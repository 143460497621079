export const UtilizationTableHead = [
	// { id: "actions", label: "Actions", sortOption: false },
	{ id: "utilization_period", label: "Period", sortOption: true },
	{ id: "tsn", label: "TSN", sortOption: true },
	{ id: "csn", label: "CSN", sortOption: true },
	{ id: "monthly_FH", label: "Monthly FH", sortOption: true },
	{ id: "monthly_FC", label: "Monthly FC", sortOption: true },
	{ id: "operating_thrust_current", label: "Operating Thrust (Current)", sortOption: true },
	{ id: "location_reg_aircraft", label: "Location (A/C Reg)", sortOption: true },
	{ id: "location_position", label: "Location (Position)", sortOption: true },
	{ id: "engine_stand_location", label: "Engine Stand Location", sortOption: true },
	{ id: "location_country", label: "Location (Country)", sortOption: true },
	{ id: "date_of_installation", label: "Date of Installation", sortOption: true },
	{ id: "date_of_removal", label: "Date of Removal", sortOption: true },
	{ id: "reason_of_removal", label: "Reason of Removal", sortOption: true },
	{ id: "attachments", label: "Attachments" },
];

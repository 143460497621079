export const MasterTableHead = [
	{ id: "actions", label: "Actions", sortOption: false },
	{ id: "part_number", label: "Part Number", sortOption: true },
	{ id: "serial_number", label: "Serial Number ", sortOption: true },
	{ id: "description", label: "Description", sortOption: true },
	{ id: "life_limited_part", label: "Life Limit", sortOption: true },
	{ id: "tsn", label: "TSN", sortOption: true },
	{ id: "csn", label: "CSN", sortOption: true },
	{ id: "remarks", label: "Remarks",sortOption: true },
	{ id: "attachments", label: "Attachments" },
];
import React, { Fragment, useEffect, useState } from 'react'
import FilterComponent from '../../../shared_elements/components/filter_component';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { dayMonthDateFormat } from '../../../constants';
import moment from 'moment';
import { Paper, Grid, TablePagination, TableCell, TableHead, TableBody, TableRow, Table, TableSortLabel } from '@material-ui/core';
import { ExportMenu } from '../../../shared_elements';
import { servceablefilterOption } from '../containers';
import { globalGetService, globalExportService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../../utils';
import { PageLoader, EmptyCollection } from "../../../shared_elements"
import STableLoader from '../../../shared_elements/components/skeleton_loader/STableLoader';
import STabsLoader from '../../../shared_elements/components/skeleton_loader/STabsLoader';

function UnServiceableEngine() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [filter, setFilter] = useState({})
	const [unserviceableData, setunserviceableData] = useState([])
	const [pageLoader, setPageLoader] = useState(false)
	const [skeltonLoader, setSkeltonLoader] = useState(false)
	const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });

	const [filterEngineTypes, setFilterEngineTypes] = useState([]);
	const [filterLessee, setFilterLessee] = useState([]);

	useEffect(() => {
		unServivceableApi()
		getLessee()
		getEngineTypes()
	}, [])


	const unServivceableApi = (query, loader) => {
		loader === "pageLoader" ? setPageLoader(true) : setSkeltonLoader(true)
		globalGetService(`camo/smbc_engine_off_wing_report_unserviceable/?per_page=20`, query)
			.then(response => {
				if (checkApiStatus(response)) {
					loader === "pageLoader" ? setPageLoader(false) : setSkeltonLoader(false)
					setunserviceableData(response.data)
					setFilter(query)
				}
			})

	}
	const createSortHandler = (key) => {
		let sortQuery = { sort: key };
		if (sortInfo.sort === key) {
			sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
		} else {
			sortQuery = { ...sortQuery, sort_by: 'asc' }
		}
		setSortInfo(sortQuery)
		unServivceableApi({ ...sortQuery, page: 1, per_page: 20 }, 'pageLoader');
	}

	const exportserviciable = (extension) => {
		setPageLoader(true)
		let queryParams = Object.assign({}, filter);
		delete queryParams.per_page 
		globalExportService(`/camo/smbc_engine_off_wing_report_unserviceable/?download=${extension}`, { ...queryParams })
			.then((response) => {
				setPageLoader(false)
				if (response.status === 500) {
					enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
				} else {
					downloadFileType(response.data, `UnServiceable Engine Reports`, extension);
					enqueueSnackbar("UnServiceable Report Download Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
				}
			});
	}
	const getEngineTypes = () => {
		globalGetService(`console/engine-types/`, { distinct: 1 })
			.then(response => {
				if (checkApiStatus(response)) {
					setFilterEngineTypes(response.data.data.engineTypes)
				}
			})
	}
	const getLessee = () => {
		globalGetService(`console/lessees/?distinct=1`)
			.then(response => {
				if (checkApiStatus(response)) {
					setFilterLessee(response.data.data.lessees)
				}
			})
	}
	const tableSorting = (key, name) => {
		return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
	}
	const filterObj = Object.assign({}, filter, delete filter?.page, delete filter?.sort, delete filter?.sort_by,)
	let filterOptions = Object.assign({}, servceablefilterOption);
	filterOptions = {
		...filterOptions,
		engine_type: {
			...filterOptions.engine_type,
			options: filterEngineTypes
		},
		lessee: {
			...filterOptions.lessee,
			options: filterLessee
		},
	}
	return (
		<Fragment >
			<Paper square style={{ height: "50px" }}>
				<Grid container spacing={2}>
					<Grid md={10}>
						<div style={{ padding: "8px 10px" }}>
							<FilterComponent
								filter={filterObj}
								filterMenu={filterOptions}
								getResponseBack={(applyFilter) => unServivceableApi({ ...applyFilter, page: 1, per_page: unserviceableData?.data?.pagination?.per_page }, 'pageLoader')}
							/>
						</div>
					</Grid>
					<Grid md={2}>
						<ul className='list-inline' style={{ float: 'right' }}>
							<li className='list-inline-item' style={{ margin: '8px 24px' }}>
								<ExportMenu
									exportReportFn={(file) => exportserviciable(file.extension)}
									files={[{ title: 'EXCEL', extension: 'xls' }]}
									title='Export'
								/>
							</li>
						</ul>
					</Grid>
				</Grid>
			</Paper>
			{skeltonLoader ? <STableLoader count={7} /> : <Paper square style={{ marginBottom: "5px" }}>
				<div className='engine-off-wing' style={{ height: (window.innerHeight - 320) + 'px' }} >
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow >
								<TableCell style={{ minWidth: '200px' }} >{tableSorting('engine_type', 'Engine Type ')}</TableCell>
								<TableCell >{tableSorting('esn', 'Engine SN')}</TableCell>
								<TableCell style={{ minWidth: '200px' }} >{tableSorting('current_lessee', 'Current Lessee')}</TableCell>
								<TableCell >{tableSorting('tsn', 'TSN')}</TableCell>
								<TableCell >{tableSorting('csn', 'CSN')}</TableCell>
								<TableCell style={{ minWidth: '200px' }} >{tableSorting('fh_fc_since_new', 'FH:FC (Since New) ')}</TableCell>
								<TableCell > {tableSorting('tslsv', 'TSLSV')}</TableCell>
								<TableCell >{tableSorting('cslsv', 'CSLSV')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('fh_fc_since_last_pr', 'FH:FC (Since Last PR)')}</TableCell>
								<TableCell >{tableSorting('status', 'Status')}</TableCell>
								<TableCell >{tableSorting('location', 'Location')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('preservation_date', 'Preservation Date')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('preservation_expiry_date', 'Preservation Expiry Date')}</TableCell>
								<TableCell style={{ minWidth: '250px' }}>{tableSorting('days_to_preservation_expiry', 'Days to Preservation Expiry')}</TableCell>
								<TableCell style={{ minWidth: '250px' }}>{tableSorting('removal_reason', 'Removal Reason')}</TableCell>
								<TableCell style={{ minWidth: '250px' }}>{tableSorting('current_sv_location', 'Current SV Location')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('current_sv_type', 'Current Shop Visit Type')}</TableCell>
								<TableCell style={{ minWidth: '250px' }}>{tableSorting('current_sv_workscope', 'Current Shop Visit Workscope')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('removal_date', 'Removal Date')}</TableCell>
								<TableCell style={{ minWidth: '250px' }}>{tableSorting('days_since_engine_removal', 'Days Since Engine Removal')}</TableCell>
								<TableCell style={{ minWidth: '250px' }}>{tableSorting('removal_to_induction_dats', 'Removal-to-Induction Days')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('induction_date', 'SV Induction Date')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('expected_date', 'Expected RTS Date')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('expected_sv_tat_dats', 'Expected SV TAT (Days)')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('target_in_wing_hours', 'Target On-Wing Hours')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('target_in_wing_cycle', 'Target On-Wing Cycle')}</TableCell>
								<TableCell style={{ minWidth: '200px' }}>{tableSorting('min_build_standard', 'Min Build Standard')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{unserviceableData?.data?.results.map((item, index) =>
								<TableRow>
									<TableCell >{item.engine_type || '--'}</TableCell>
									<TableCell >{item.esn || '--'}</TableCell>
									<TableCell >{item.current_lessee || '--'}</TableCell>
									<TableCell >{item.tsn || '--'}</TableCell>
									<TableCell >{item.csn || '--'}</TableCell>
									<TableCell >{item.fh_fc_since_new || '--'}</TableCell>
									<TableCell >{item.tslsv || '--'}</TableCell>
									<TableCell >{item.cslsv || '--'}</TableCell>
									<TableCell >{item.fh_fc_since_last_pr || '--'}</TableCell>
									<TableCell >{item.status || '--'}</TableCell>
									<TableCell >{item.location || '--'}</TableCell>
									<TableCell >{item.preservation_date ? moment(item.preservation_date).format(dayMonthDateFormat) :  "--"}</TableCell>
									<TableCell >{item.preservation_expiry_date ? moment(item.preservation_expiry_date).format(dayMonthDateFormat) :  "--"}</TableCell>
									<TableCell >{item.days_to_preservation_expiry === 0 ? 0 : item.days_to_preservation_expiry || '--'}</TableCell>
									<TableCell >{item.removal_reason || '--'}</TableCell>
									<TableCell >{item.current_sv_location || '--'}</TableCell>
									<TableCell >{item.current_sv_type || '--'}</TableCell>
									<TableCell >{item.current_sv_workscope || '--'}</TableCell>
									<TableCell >{item.removal_date ? moment(item.removal_date).format(dayMonthDateFormat) :  "--"}</TableCell>
									<TableCell >{item.days_since_engine_removal === 0 ? 0 : item.days_since_engine_removal || '--'}</TableCell>
									<TableCell >{item.removal_to_induction_dats === 0 ? 0 : item.removal_to_induction_dats || '--'}</TableCell>
									<TableCell >{item.induction_date ? moment(item.induction_date).format(dayMonthDateFormat) :  "--"}</TableCell>
									<TableCell >{item.expected_date ? moment(item.expected_date).format(dayMonthDateFormat) :  "--"}</TableCell>
									<TableCell >{item.expected_sv_tat_dats === 0 ? 0 : item.expected_sv_tat_dats || '--'}</TableCell>
									<TableCell >{item.target_in_wing_hours === 0 ? 0 : item.target_in_wing_hours || '--'}</TableCell>
									<TableCell >{item.target_in_wing_cycle === 0 ? 0 : item.target_in_wing_cycle || '--'}</TableCell>
									<TableCell >{item.min_build_standard || '--'}</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
					{!unserviceableData?.data ? <EmptyCollection description={'No records found'} /> : null}
				</div>
				<TablePagination
					rowsPerPageOptions={[20, 50, 100, 200]}
					component="div"
					count={unserviceableData?.data?.pagination?.total}
					rowsPerPage={parseInt(unserviceableData?.data?.pagination?.per_page)}
					page={unserviceableData?.data?.pagination?.current_page - 1}
					backIconButtonProps={{ "aria-label": "previous page" }}
					nextIconButtonProps={{ "aria-label": "next page" }}
					onChangePage={(event, newPage) => unServivceableApi({ per_page: unserviceableData.data?.pagination?.per_page, page: newPage + 1 }, 'pageLoader')}
					onChangeRowsPerPage={(event) => unServivceableApi({ per_page: event.target.value }, "pageLoader")}
				/>
			</Paper>}
			{pageLoader ? <PageLoader /> : null}
		</Fragment >
	)
}

export default withRouter(UnServiceableEngine)

import { globalGetService } from "../../utils/globalApiServices";
import { getLocalStorageInfo } from "../../utils";
import config from '../../config'

export function fillChartAmpApi() {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/amp/analytics/`).then((response) => {
			resolve(response);
		});
	});
}
export function fillChartHtApi(value) {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/ht/analytics/${getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 )  ? value === 0 && 2 : value === 0 ? 1 : 2}/`).then((response) => {
			resolve(response);
		});

	});
}
export function fillChartAdApi(value) {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/aird/analytics/${getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 )  ? value === 0 && 2 : value === 0 ? 1 : 2}/`).then((response) => {
			resolve(response);
		});
	});
}
export function fillChartSbApi(value) {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/sb/analytics/${getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 )  ? value === 0 && 2 : value === 0 ? 1 : 2}/`).then((response) => {
			resolve(response);
		});
	});
}
export function fillChartSrApi() {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/repair/analytics/`).then((response) => {
			resolve(response);
		});
	});
}
export function fillChartModsApi() {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/mods/analytics/`).then((response) => {
			resolve(response);
		});
	});
}
export function fillChartLLpApi() {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/llp/analytics/`).then((response) => {
			resolve(response);
		});
	});
}
export function fillChartSoftTimeApi() {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/soft_time/analytics/`).then((response) => {
			resolve(response);
		});
	});
}


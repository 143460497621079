import React, { useEffect, useState } from 'react'
import { DeletedTab } from '../Components'
import { Button, TextField, Grid, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Tooltip, IconButton, Checkbox, TablePagination, Paper } from '@material-ui/core'
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { withRouter } from "react-router-dom";
import RevisionHistory from '../../../../shared_elements/components/RevisionHistory';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import STableLoader from '../../../../shared_elements/components/skeleton_loader/STableLoader';
import STabsLoader from '../../../../shared_elements/components/skeleton_loader/STabsLoader';
import { globalGetService, globalDeleteService, globalPutService } from '../../../../utils/globalApiServices';
import { dayMonthDateFormat } from '../../../../constants';
import { checkApiStatus } from '../../../../utils';
import { EmptyCollection, PageLoader, DeletePopUp } from '../../../../shared_elements';

const Inventory = ({ match }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState({ list: [], pagination: {} })
    const [isLoading, setLoading] = useState(false)
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [skeltonLoader, setSkeltonLoader] = useState(false)
    const [delPermanent, setPermanentDel] = useState({ modal: false, data: null, type: '' })
    const [search, setSearch] = useState('')
    const [bulk, setBulk] = useState({ modal: false, ids: [], title: '', type: '' })


    useEffect(() => {
        getDeletedListApi({per_page:20}, 'skeltonLoader')
    }, [])

    const onFiledChange = (key, value) => {
        setSearch(value)
        getDeletedListApi({per_page:20, [key]: value })
    }

    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        getDeletedListApi({ ...sortQuery, per_page: 20 }, 'pageLoader');
    }
    const toggleBulkOps = async (flag, type, item) => {
        try {
            if (type === 'bulk') {
                const response = await getDeletedListApi({per_page:data?.pagination?.total}, 'pageLoader');
                setBulk({ ...bulk, type: 'bulk', ids: flag ? response.results : [] });
            } else {
                setBulk({ ...bulk, type: 'single', ids: flag ? [...bulk.ids, item] : bulk.ids.filter(optionItem => optionItem.id !== item.id) })
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getDeletedListApi = async (query, loaderType) => {
        try {
            loaderType === "skeltonLoader" ? setSkeltonLoader(true) : setLoading(true);
            const response = await globalGetService(`/camo/inventory/trash/?asset=${match?.params?.asset}&asset_type=${match?.params?.assetType}`, { ...query });
            loaderType === "skeltonLoader" ? setSkeltonLoader(false) : setLoading(false);
            if (response.status === 200) {
                setData(response.data);
                return response.data;
            } else {
                throw new Error(`Failed to fetch data: ${response.status}`);
            }
        } catch (error) {
            loaderType === "skeltonLoader" ? setSkeltonLoader(false) : setLoading(false);
            console.error("Error fetching deleted list:", error);
        }
    }
    const deletePermanent_RestoreApi = () => {
        setLoading(true)
        if (delPermanent?.type === 'delete') {
            globalDeleteService(`camo/inventory/trash/${delPermanent?.data?.id}/`)
            .then((response) => {
                setPermanentDel({ modal: false });setBulk({ modal: false, ids: []});  getDeletedListApi({ per_page: 20 })
                if (checkApiStatus(response)) {
                    setLoading(false);
                    enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })
        } else {
            globalPutService(`camo/inventory/trash/${delPermanent?.data?.id}/`)
            .then((response) => {
                setPermanentDel({ modal: false });setBulk({ modal: false, ids: []});  getDeletedListApi({ per_page: 20 })
                if (checkApiStatus(response)) {
                    setLoading(false);
                    enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })
        }
    }

    const bulkDeletePermanent_RestoreApi = () => {
        setLoading(true)
        if (bulk?.title === 'Delete') {
            globalDeleteService(`camo/inventory/trash/bulk_delete/${match?.params?.assetType}/${match?.params?.asset}/`, { delete_ids: bulk?.ids.map(item => item?.id) })
            .then((response) => {
                setLoading(false); setBulk({ modal: false, ids: [] }); getDeletedListApi({ per_page: 20 })
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }

            })
        } else {
            globalPutService(`camo/inventory/trash/bulk_restore/${match?.params?.assetType}/${match?.params?.asset}/`, { delete_ids: bulk?.ids.map(item => item?.id) })
                .then((response) => {
                    setLoading(false); setBulk({ modal: false, ids: [] }); getDeletedListApi({ per_page: 20 })
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                    } else {
                        enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                    }
                })
        }
    }


    let timezone = moment().format()
    const timestamp = Math.floor(Date.now() / 1000);
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }

    return (
        <section className='camo-fleet-module'>
            <DeletedTab tabIndex='inventory' />
            {skeltonLoader ? <STabsLoader count={1} width={250} height={40} /> : <Grid container >
                <Grid item xs={12} md={5} sm={5}>
                    <TextField
                        variant="outlined"
                        margin='normal'
                        fullWidth
                        className='deleted-search-box'
                        label="Search"
                        placeholder='Search By Part Number,Serial Number,Description'
                        InputLabelProps={{ shrink: true }}
                        value={search}
                        onChange={(e) => onFiledChange('search', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                    {bulk?.type && bulk?.ids?.length ?
                        <div className='flex-centered' style={{ marginTop: '20px' }}>
                            <ul className='list-inline flex-centered'>
                                <li className="list-inline-item" style={{ cursor: 'pointer' }} onClick={() => setBulk({ modal: false, ids: [], title: '', type: '' })}>
                                    <Tooltip title='Cancel' arrow><CloseIcon color='primary' /></Tooltip>
                                </li>
                                <li className="list-inline-item" style={{ cursor: 'pointer', margin: '3px' }}>
                                    <Button onClick={() => setBulk({ ...bulk, title: 'Restore', modal: true })} size="small" variant="outlined" color="primary">Restore ({bulk?.ids?.length})</Button>
                                </li>
                                <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                                    <Button onClick={() => setBulk({ ...bulk, title: 'Delete', modal: true })} size="small" variant="outlined" color="secondary">Delete ({bulk?.ids?.length})</Button>
                                </li>
                            </ul>
                        </div> : null
                    }
                </Grid>
            </Grid>}
            {skeltonLoader ? <STableLoader count={7} /> : <>
                <Paper variant='outlined' square>
                    <div style={{ height: `${window.innerHeight - 295}px`, overflow: 'auto' }}>
                        <Table className='mui-table-format' stickyHeader={true} >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            color="primary"
                                            size="small"
                                            onChange={(e) => toggleBulkOps(e.target.checked, 'bulk')}
                                            checked={data?.results?.length === bulk?.ids?.length && data?.results?.length > 0}
                                        />
                                    </TableCell>
                                    <TableCell>{tableSorting('part_number', 'Part Number')}  </TableCell>
                                    <TableCell>{tableSorting('serial_number', 'Serial Number')}  </TableCell>
                                    <TableCell>{tableSorting('description', 'Description')}  </TableCell>
                                    <TableCell>Deleted By</TableCell>
                                    <TableCell>{tableSorting('delete_timestamp', 'Deleted At')}</TableCell>
                                    <TableCell> Action(s) </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.results?.length > 0 && data?.results.map((item, index) =>
                                    <TableRow>
                                        <TableCell width='5'>
                                            <Checkbox
                                                color="primary"
                                                size="small"
                                                checked={bulk?.ids.find(items => items?.id === item?.id) ? true : false}
                                                onChange={(e) => toggleBulkOps(e.target.checked, 'single', item)}
                                            />
                                        </TableCell>
                                        <TableCell width='200'> {item?.part_number || '--'}</TableCell>
                                        <TableCell width='200'> {item?.serial_number || '--'}</TableCell>
                                        <TableCell width='300'> {item?.description || '--'}</TableCell>
                                        <TableCell width='200'> {item?.deleted_by?.name || '--'} </TableCell>
                                        <TableCell width='200'> {moment(item?.delete_timestamp).format(dayMonthDateFormat) || '--'} </TableCell>
                                        <TableCell>
                                            <Tooltip title="Delete" placement='top' >
                                                <IconButton onClick={() => setPermanentDel({ modal: true, data: item, type: 'delete' })} style={{ padding: 'unset' }} color='secondary' >
                                                    <DeleteOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Restore" placement='top' >
                                                <IconButton onClick={() => setPermanentDel({ modal: true, data: item, type: 'restore' })} style={{ padding: 'unset', }} color="primary" >
                                                    <SettingsBackupRestoreIcon style={{ fontSize: '1.25rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                            <RevisionHistory buttonType={true} url={`audit/camo/inventorylist/?object_id=${item?.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`} />
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            </TableBody>
                        </Table>
                        {!data?.results?.length ? <EmptyCollection title="No Records Founds" /> : null}
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        component="div"
                        count={data?.pagination?.total}
                        rowsPerPage={parseInt(data?.pagination?.per_page)}
                        page={data?.pagination?.current_page - 1}
                        backIconButtonProps={{ "aria-label": "previous page" }}
                        nextIconButtonProps={{ "aria-label": "next page" }}
                        onChangePage={(event, newPage) => getDeletedListApi({ per_page:data?.pagination?.per_page, page: newPage + 1 }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => getDeletedListApi({ per_page: event.target.value, }, "pageLoader")}
                    /></Paper>

            </>
            }
            {delPermanent.modal ?
                <DeletePopUp
                    title={delPermanent?.type === 'delete' ? "Delete Inventory" : "Restore Inventory"}
                    deleteRecordFn={() => deletePermanent_RestoreApi()}
                    modal={() => setPermanentDel({ modal: true })}
                    content={delPermanent?.type === 'delete' ? 'Do you really want to delete this record permanently?' : 'Do you really want to restore this record'}
                    toggleModalFn={() => setPermanentDel({ modal: false })}
                    confirmText={delPermanent?.type === 'delete' ? "Delete" : "Restore"}
                /> : null
            }
            {bulk.modal ?
                <DeletePopUp
                    title={bulk?.title === 'Delete' ? "Delete Inventory" : "Restore Inventory"}
                    deleteRecordFn={() => bulkDeletePermanent_RestoreApi()}
                    modal={() => setBulk({ modal: true })}
                    content={bulk?.title === 'Delete' ? `Do you really want to delete ${bulk?.ids.length > 1?'these':'this'} record` : `Do you really want to Restore ${bulk?.ids.length > 1?'these':'this'} record`}
                    toggleModalFn={() => setBulk({ modal: false, ids: [] })}
                    confirmText={bulk?.title === 'Delete' ? "Delete" : "Restore"}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default withRouter(Inventory)

import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
import config from "../../../config"
import { getLocalStorageInfo } from "../../../utils"
const FleetAdTableHeader = ({sortInfo,createSortHandler}) => {
    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }} className="fleet-ad-header">
            <TableRow>
                <TableCell colSpan={smbcInstance ? 9 : 11} align="center"></TableCell>
                {smbcInstance ? <TableCell colSpan={2} align="center">EFFECTIVE DATE</TableCell> : <TableCell colSpan={3} align="center">AD Compliance</TableCell>}
                <TableCell colSpan={smbcInstance ? 5 : 3} align="center"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Action </TableCell>
                <TableCell >
                    <TableSortLabel active={sortInfo.sort === (smbcInstance ? 'faa_ad' : 'ata') ? true : false} direction={sortInfo.sort === (smbcInstance ? 'faa_ad' : 'ata') ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(smbcInstance ? 'faa_ad' : 'ata')} >
                        {smbcInstance ? " FAA AD" : "ATA Chapter"}
                    </TableSortLabel>
                </TableCell>
                <TableCell className="ad-list-style">
                    <TableSortLabel active={sortInfo.sort === (smbcInstance ? 'faa_superseded' : 'ad_no') ? true : false} direction={sortInfo.sort === (smbcInstance ? 'faa_superseded' : 'ad_no') ? sortInfo.sort_by :'asc'} onClick={() => createSortHandler(smbcInstance ? 'faa_superseded' : 'ad_no')} >
                        {smbcInstance ? " FAA Superseded" : "AD Reference Number"}
                    </TableSortLabel>
                </TableCell>
                {smbcInstance ? <TableCell>
                    <TableSortLabel active={sortInfo.sort === "easa_ad" ? true : false} direction={sortInfo.sort === "easa_ad" ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler("easa_ad")} >
                        EASA AD
                    </TableSortLabel>
                </TableCell> : null}
                <TableCell className="ad-list-style">
                    <TableSortLabel active={sortInfo.sort === (smbcInstance ? 'easa_superseded' : 'issuing_authority') ? true : false} direction={sortInfo.sort === (smbcInstance ? 'easa_superseded' : 'issuing_authority') ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(smbcInstance ? 'easa_superseded' : 'issuing_authority')} >
                        {smbcInstance ? "EASA Superseded" : "AD Issuing Authority " || "--"}
                    </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: "200px", textAlign: "left" }}>Description</TableCell>
                {smbcInstance ? <TableCell className="ad-list-style">Related Document</TableCell> : null}
                <TableCell className="ad-list-style">Applicability</TableCell>
                <TableCell className="ad-list-style">Effectivity</TableCell>
                {smbcInstance ? <TableCell>FAA</TableCell> : null}
                {smbcInstance ? <TableCell>EASA</TableCell> : null}
                {smbcInstance ? null : <TableCell>AD type </TableCell>}
                {smbcInstance ? null : <TableCell className="ad-list-style">AD Effective Date </TableCell>}
                {smbcInstance ? null : <TableCell className="ad-list-style" >AD Compliance Period	 </TableCell>}
                <TableCell className="ad-list-style">AD Compliance Status </TableCell>
                {smbcInstance ? null : <TableCell>TSN	 </TableCell>}
                {smbcInstance ? null : <TableCell>CSN </TableCell>}
                {smbcInstance ? null : <TableCell>Date </TableCell>}
                {smbcInstance ? null : <TableCell>SB Number</TableCell>}
                {smbcInstance ? <TableCell>Reference</TableCell> : null}
                {smbcInstance ? <TableCell>Remarks</TableCell> : null}
                <TableCell>Attachments </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default FleetAdTableHeader
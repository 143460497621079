import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Grid, Breadcrumbs, Link, Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { checkApiStatus } from '../../utils';
import { globalGetService, globalPutService } from '../../utils/globalApiServices';
import PageLoader from './PageLoader';

const LinkFiles = ({ handleClose, uuid, actionType, editFormId, getResponseBack, match, onLinkFile, modType, uploadFileInfo, last_used_folder_uuid, attachments, type, listResponseBack }) => {
	const disabledaddlinkFile = attachments?.map(item => item.file_name)
	const disabledEditlinkFile = uploadFileInfo?.checklist?.files?.map(item => item.name)
	const disabledaddlinkFolder = uploadFileInfo?.checklist?.folders?.map(item => item.name)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [isLoading, setLoading] = useState(false);
	const [checkListData, setCheckListData] = useState({ navigation: [], folders: [], files_in_current_folder: [] })
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [selectedFolder, setSelectedFolder] = useState([]);

	useEffect(() => {
		getCheckListLinkData(last_used_folder_uuid ? last_used_folder_uuid : uuid, 'firstCall')
	}, []);

	const getCheckListLinkData = (uuid) => {
		setLoading(true);
		globalGetService(`${actionType === 'add' ? `camo/asset/${match.params.asset}` : `camo/${modType}/${editFormId}`}/record-folders-files/${uuid ? uuid : last_used_folder_uuid}/`, { showFiles: true })
			.then(response => {
				setLoading(false);
				if (checkApiStatus(response)) {
					setCheckListData(response.data.data);
				}
			})
	}
	// const onAddRemoveFile = (flag, file) => {
	// 	if (flag) {
	// 		setSelectedFiles([...selectedFiles, file]);
	// 	} else {
	// 		setSelectedFiles(selectedFiles.filter(item => item.id !== file.id));
	// 	}
	// }
	const onAddRemoveFile = (flag, file, type) => {
		if (type === "folder") {
			if (flag) {
				setSelectedFolder([...selectedFolder, file]);
			} else {
				setSelectedFolder(selectedFolder.filter(item => item.id !== file.id));
			}
		} else {
			if (flag) {
				setSelectedFiles([...selectedFiles, file]);
			} else {
				setSelectedFiles(selectedFiles.filter(item => item.id !== file.id));
			}
		}
	}
	// const onSubmitFileLink = () => {
	// 	let payload = {
	// 		file_ids: selectedFiles.map(file => file.id).join(','),
	// 	};
	// 	globalPutService(`${actionType === 'add' ? `camo/asset/${match.params.asset}` : `camo/${modType}/${editFormId}`}/document-checklists/`, payload)
	// 		.then(response => {
	// 			if (checkApiStatus(response)) {
	// 				listResponseBack(response.data.data)
	// 				handleClose()
	// 				getResponseBack(response.data.data);

	// 				enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
	// 			} else {
	// 				enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
	// 			}
	// 		});
	// }
	const onSubmitFileLink = () => {
		let payload = {};
		if (selectedFolder.some(item => item.type === 'folder') && selectedFiles.some(item => item.type === 'file')) {
			payload.folder_ids = selectedFolder.map(file => file.id).join(',');
			payload.file_ids = selectedFiles.map(file => file.id).join(',');
		} else if (selectedFolder.some(item => item.type === 'folder')) {
			payload.folder_ids = selectedFolder.map(file => file.id).join(',');
		} else {
			payload.file_ids = selectedFiles.map(file => file.id).join(',');
		}
		globalPutService(`${actionType === 'add' ? `camo/asset/${match.params.asset}` : `camo/${modType}/${editFormId}`}/document-checklists/`, payload)
			.then(response => {
				if (checkApiStatus(response)) {
					listResponseBack(response.data.data)
					handleClose()
					getResponseBack(response.data.data);
					enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
				} else {
					enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
				}
			});
	}
	const selectedFilesData = () => {
		// onLinkFile(selectedFiles, '', type)
		onLinkFile([...selectedFiles, ...selectedFolder],'', type)
		handleClose()
	}

	return (
		<Fragment>
			<DialogTitle id="scroll-dialog-title" style={{ padding: "10px 24px" }}>Link File(s)</DialogTitle>
			<DialogContent dividers={true}>
				<div style={{ width: '720px' }} className='checklist-file-linking'>
					<Grid container spacing={1}>
						<Grid item md={7}>
							<div className='linking-navigation'>
								<Breadcrumbs maxItems={3} aria-label="breadcrumb">
									{checkListData.navigation.map((nav, index) =>
										checkListData.navigation.length - 1 == index ?
											<Link onClick={() => getCheckListLinkData(nav.uuid)} className="list-inline-item navigation_modal">{nav.name}</Link>
											:
											<Link onClick={() => getCheckListLinkData(nav.uuid)} className="list-inline-item navigation_modal">{nav.name}</Link>
									)}
								</Breadcrumbs>
							</div>
							<Grid container spacing={1}>
								{checkListData?.folders?.length || checkListData?.files_in_current_folder?.length ?
									<>
										{checkListData?.folders.map((folder, index) =>
											<Grid container spacing={0}>
												<Grid item md={10}>
													<h4 className='flex-centered' onClick={() => getCheckListLinkData(folder.uuid)}>
														<FolderOpenIcon color='primary' fontSize='small' /> <span>{folder.name}</span>
													</h4>
												</Grid>
												<Grid item md={2}>
													<Checkbox
														checked={selectedFolder.map(item => item.id).includes(folder.id) ? true : false}
														onChange={(e) => onAddRemoveFile(e.target.checked, folder, 'folder')}
														color='primary'
														size='small'
														disabled={disabledaddlinkFolder?.includes(folder?.name) ? true : false}
													/>
												</Grid>
											</Grid>
										)}
										{checkListData.files_in_current_folder.map((file, index) =>
											<Grid item md={12}>
												<Grid container spacing={0}>
													<Grid item md={10}>
														<h4 className='flex-centered'>
															<InsertDriveFileIcon color='primary' fontSize='small' />
															<span>{file.name}</span>
														</h4>
														<p className='file-location'>{file.location}</p>
													</Grid>
													<Grid item md={2}>
														<Checkbox
															checked={selectedFiles.map(item => item.id).includes(file.id) ? true : false}
															onChange={(e) => onAddRemoveFile(e.target.checked, file)}
															color='primary'
															size='small'
															disabled={disabledaddlinkFile?.includes(file?.name) || disabledEditlinkFile?.includes(file?.name) ? true : false}
														/>
													</Grid>
												</Grid>
											</Grid>
										)}
									</>
									: <Grid item md={12}>
										<p style={{ textAlign: 'center' }}>No folder / file(s) found</p>
									</Grid>
								}
							</Grid>
						</Grid>
						<Grid item md={5} style={{ borderLeft: '1px solid #d7d7d7' }}>
							<div className='selected-files'>
								<h3>Selected File's ({selectedFiles.length})</h3>
								{selectedFiles.length ?
									<div className='selected-files-blk'>
										{selectedFiles.map((file, index) =>
											<div className='file-list' key={index}>
												<div>
													<h4><span>{file.name}</span></h4>
													<p>{file.location}</p>
												</div>
												<span onClick={() => onAddRemoveFile(false, file)} className='remove-file'><RemoveCircleIcon color='secondary' fontSize='small' /></span>
											</div>
										)}
										{selectedFolder.map((file, index) =>
											<div className='file-list' key={index}>
												<div>
													<h4><span>{file.name}</span></h4>
													<p>{file.location}</p>
												</div>
												<span onClick={() => onAddRemoveFile(false, file, 'folder')} className='remove-file'><RemoveCircleIcon color='secondary' fontSize='small' /></span>
											</div>
										)}
									</div> : <p style={{ textAlign: 'center', background: '#fff' }}>No file(s) selected</p>
								}
							</div>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose()} color="primary" size='small'>Cancel</Button>
				{selectedFiles.length || selectedFolder.length ?
					<Button onClick={actionType === 'add' ? selectedFilesData : onSubmitFileLink} variant="contained" color="primary" size='small'>Save</Button> : null
				}
			</DialogActions>
			{isLoading ? <PageLoader /> : null}
		</Fragment>
	)
}
export default withRouter(LinkFiles);

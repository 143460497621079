import { getLocalStorageInfo } from "../../../../utils";

export const OccmTableHeadMain = [];

export const OccmTableHead = [
	{ id: "actions", label: "Actions", sortOption: false },
	{ id: "ata_chapter", label: "ATA Chapter", sortOption: true },
	{ id: "part_description", label: "Part Description", sortOption: true },
	{ id: "part_number", label: "Part Number", sortOption: true },
	{ id: "part_serial_number", label: "Part Serial Number", sortOption: true },
	{
		id: "part_installation_date",
		label: "Part Installation Date",
		sortOption: true,
	},
	{
		id: "aircraft_tsn",
		label: "Aircraft TSN at Installation",
		sortOption: true,
	},
	{
		id: "aircraft_csn",
		label: "Aircraft CSN at Installation",
		sortOption: true,
	},

	{
		id: "part_tsn",
		label: "Part TSN at Installation",
		sortOption: true,
	},
	{
		id: "part_csn",
		label: "Part CSN at installation",
		sortOption: true,
	},
	{ id: "occm_requirement", label: "OCCM Requirement", sortOption: true },
	{ id: "remark", label: "Remarks",sortOption: true },
	{ id: "attachments", label: "Attachments" },
];

export const assetFilterOps = {
	aircraft_type: {
		inputType: "dropdown",
		placeholder: "Select Aircraft Type",
		title: "Aircraft Type",
		options: [],
		labelKey: "name",
		valueKey: "id",
		multiple: true,
	},
	engine_type: {
		inputType: "dropdown",
		placeholder: "Select Engine Type",
		title: "Engine Type",
		options: [],
		labelKey: "name",
		valueKey: "id",
		multiple: true,
	},
	lessee: {
		inputType: "dropdown",
		placeholder: "Select Lessee",
		title: "Lessee",
		options: [],
		labelKey: "name",
		valueKey: "slug",
		multiple: true,
	},
	lessor_name: {
		inputType: "text",
		placeholder: "Enter Lessor",
		title: "Lessor",
	},
	owner: {
		inputType: "text",
		placeholder: "Enter Owner ",
		title: "Owner",
	},
	ownership_type: {
		inputType: "dropdown",
		placeholder: "Select Ownership Type",
		title: "Ownership",
		options: [
			{ label: "Owned", value: 1 },
			{ label: "Managed", value: 2 },
		],
		labelKey: "label",
		valueKey: "value",
		multiple: true,
	},
	registration: {
		inputType: "text",
		placeholder: "Enter Registeration Number",
		title: "Registeration Number",
	},
	portfolio: {
		inputType: "text",
		placeholder: "Enter Portfolio",
		title: "Portfolio",
	},
	serial_number: {
		inputType: "text",
		placeholder: "Enter Serial Number",
		title: "Serial Number",
	},
	status: {
		inputType: "dropdown",
		placeholder: "Select Status",
		title: "Status",
		options:
			getLocalStorageInfo() &&
				getLocalStorageInfo().defaultLessor &&
				getLocalStorageInfo().defaultLessor.id === 442
				? [
					{ label: "Prospect", value: "11" },
					{ label: "In Storage", value: "10" },
					{ label: "Lease Return", value: "9" },
					{ label: "MOU Signed", value: "8" },
					{ label: "MOU Issued", value: "7" },
					{ label: "Lease Issued", value: "6" },
					{ label: "Archive", value: "5" },
					{ label: "Written Off/ Sold / Part out", value: "4" },
					{ label: "OnGround", value: "3" },
					{ label: "Off Lease", value: "2" },
					{ label: "On Lease", value: "1" },
				]
				: [
					{ label: "Archive", value: "5" },
					{ label: "On Lease", value: "1" },
					{ label: "Off Lease", value: "2" },
					{ label: "On Ground", value: "3" },
					{ label: "Written Off/ Sold / Part out", value: "4" },
				],
		labelKey: "label",
		valueKey: "value",
		multiple: true,
	},
};

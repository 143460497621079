import React, { useState } from 'react'
import { useSnackbar } from 'notistack';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, Tooltip, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { isMobile } from 'react-device-detect';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { backendDateFormat, displayDateFormat, fieldDateFormat,dayMonthDateFormat } from '../../../constants';
import moment from 'moment';
import { globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { notificationPreferences, notificationDays, notificationWeekdays,notificationFebDays,notificationthirtyDays } from './index.js';
export default function AddEditAlerts({ toggleModalFn, alertModal, getResponseBack, usersList }) {
    const [alertData, setAlertData] = useState(alertModal?.data)
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const onFieldChange = (key, value) => {
        if(key === 'valid_till'){
            setAlertData({
                ...alertData,
                [key]: value,
                ['notify_every_month_on']:{
                    value: null,
                    label: null
                },
            })
        }else{
            setAlertData({
                ...alertData,
                [key]: value
            })
        }
       

    }
    const saveAlertFn = () => {
        let validationInputs = {}
        validationInputs = {
            ...validationInputs,
            activity_type: alertData?.activity_type ? '' : 'Please select activity type',
            users: alertData?.users?.length ? '' : 'Please select user',
            alert_frequency_type: alertData?.alert_frequency_type ? '' : 'Please select notification preference',
            valid_till: alertData.valid_till ? moment(alertData.valid_till).isValid() ? moment(alertData.valid_till).diff(moment().format(displayDateFormat)) < 0 ? 'Cannot be a past date' : moment(alertData.valid_till).diff(moment().format(displayDateFormat), 'years') > 3 ? 'Please select thess than three years' : '' : "Please enter valid Date " : 'Please enter Valid till Date'
        }
        if (alertData?.alert_frequency_type?.value == 2) {
            validationInputs = {
                ...validationInputs,
                notify_every_week_on: alertData?.notify_every_week_on?.value.toString() ? '' : 'Please select a day',
            }
        }
        if (alertData?.alert_frequency_type?.value == 3) {
            validationInputs = {
                ...validationInputs,
                notify_every_month_on: alertData?.notify_every_month_on?.value ? '' : 'Please select a date',
            }
        }
        if (Object.keys(validationInputs).every(item => { return validationInputs[item] === '' })) {
            let payload = Object.assign({}, alertData)
            payload = {
                ...payload,
                assets: payload?.assets?.map(item => { return { id: item?.id, asset_type: item?.asset_type, asset_name: item?.unique_name } })
            }
            setLoading(true);
            if (alertData?.id) {

                globalPutService(`camo/reports/custom-alerts/${alertData?.id}/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        } else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        setLoading(false)
                    })
            }
            else {
                globalPostService(`camo/reports/custom-alerts/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        } else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        setLoading(false)
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    let viewMode = alertModal?.mode === 'view' ? true : false
    const activityTypeList = [{ label: 'Portfolio', value: 1 }, { label: 'Serviceable Report', value: 2 }, { label: 'Unserviceable Report', value: 3 }]
    const validTillFormatted = moment(alertData?.valid_till).format(dayMonthDateFormat);
    const thirtyDays=["Apr", "Sep", "Nov", "Jun"].some(month => validTillFormatted.includes(month))
   
  return (
        <>
            <Dialog
                open={alertModal.flag}
                // onClose={toggleModalFn}
                className='records-dataroom-modal'
                maxWidth='md'
            >
                <DialogTitle id="scroll-dialog-title"> {viewMode ? 'View' : alertModal?.data?.id ? 'Edit' : 'Add'} Custom Alerts</DialogTitle>
                <DialogContent dividers={true} style={isMobile ? {} : { width: "700px" }}>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}>
                            <Autocomplete
                                disabled={viewMode}
                                options={activityTypeList.filter(item => [1, 2, 3].includes(item?.value))}
                                getOptionLabel={option => option.label}
                                id="activity_type"
                                value={activityTypeList.find(item => item?.value == alertData?.activity_type?.value)}
                                onChange={(e, value) => onFieldChange('activity_type', value)}
                                renderInput={params => <TextField required={true} {...params} placeholder='Select activity type' error={error?.activity_type} helperText={error?.activity_type || ''} onFocus={() => setError({ ...error, activity_type: '' })} label="Activity Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required={true}
                                    margin="normal"
                                    disabled={viewMode}
                                    id="valid_till"
                                    label="Alert Valid Till"
                                    minDate={moment()}
                                    maxDate={moment().add(3, 'years')}
                                    format={fieldDateFormat}
                                    fullWidth
                                    placeholder='Select valid till date'
                                    error={error?.valid_till}
                                    helperText={error?.valid_till || ''}
                                    InputLabelProps={{ shrink: true }}
                                    value={alertData.valid_till ? alertData.valid_till : null}
                                    onChange={(data, value) => {onFieldChange('valid_till', data ? moment(data).format(backendDateFormat) : data);setError({ ...error, valid_till: '' })}}
                                    inputVariant='outlined'
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Autocomplete
                                disabled={viewMode}
                                options={usersList}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={option => option?.name ? option?.name : option?.email ? option?.email : '--'}
                                value={alertData?.users || []}
                                multiple={true}
                                limitTags={viewMode ? 10000 : 5}
                                renderInput={params => <TextField error={error?.users} helperText={error?.users || ''} onFocus={() => setError({ ...error, users: '' })} placeholder="Enter the name or email" {...params} required label="Users" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                onChange={(paramsKey, emails) => { onFieldChange('users', emails); setError({ ...error, users: '' }) }}
                            />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Autocomplete
                                disabled={viewMode}
                                options={notificationPreferences}
                                getOptionLabel={option => option.label}
                                id="alert_frequency_type"
                                value={alertData?.alert_frequency_type || null}
                                onChange={(e, value) => onFieldChange('alert_frequency_type', value)}
                                renderInput={params => <TextField required={true} {...params} placeholder='Select notification preference' error={error?.alert_frequency_type} helperText={error?.alert_frequency_type || ''} onFocus={() => setError({ ...error, alert_frequency_type: '' })} label="Notification Preference" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />

                        </Grid>
                        {alertData.alert_frequency_type?.value == 2 ?
                            <Grid item sm={6} xs={12}>
                                <Autocomplete
                                    disabled={viewMode}
                                    options={notificationWeekdays}
                                    getOptionLabel={option => option.label}
                                    id="notify_every_week_on"
                                    value={alertData?.notify_every_week_on || null}
                                    onChange={(e, value) => onFieldChange('notify_every_week_on', value)}
                                    renderInput={params => <TextField required={true} {...params} placeholder='Select notification day' error={error?.notify_every_week_on} helperText={error?.notify_every_week_on || ''} onFocus={() => setError({ ...error, notify_every_week_on: '' })} label="Receive Notification Every Week On" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            : null}
                        {alertData.alert_frequency_type?.value == 3 ?
                            <Grid item sm={6} xs={12}>
                                <Autocomplete
                                    disabled={viewMode || !alertData?.valid_till}
                                    options={thirtyDays ? notificationthirtyDays : moment(alertData?.valid_till).format(dayMonthDateFormat).includes("Feb") ? notificationFebDays : notificationDays  || [] }
                                    getOptionLabel={option => option.label}
                                    id="notify_every_month_on"
                                    value={alertData?.notify_every_month_on || null}
                                    onChange={(e, value) => onFieldChange('notify_every_month_on', value)}
                                    renderInput={params => <TextField required={true} {...params} placeholder='Select notification date' error={error?.notify_every_month_on} helperText={error?.notify_every_month_on || ''} onFocus={() => setError({ ...error, notify_every_month_on: '' })} label="Receive Notification Every Month On" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            : null}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} size='small' onClick={toggleModalFn} color="primary" variant="text">{'Close'}</Button>
                    {!viewMode ?
                        <Button disabled={isLoading} size='small' onClick={saveAlertFn} color="primary" variant="contained">
                            {isLoading ? <CircularProgress size={24} /> : 'Save'}
                        </Button> : null
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}

import React from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { trackActivity } from '../../../utils/mixpanel';
import { red } from '@material-ui/core/colors';
const ReportsTab = ({ tabIndex = 'portfolio', history }) => {
    return (
        <Paper square style={{ marginBottom: '10px' }}>
            <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => history.push(`/reports/${newValue}`)} className={'no-margin-tab'}>
                <Tab label='Portfolio' value="portfolio" onClick={() => trackActivity('Tab Clicked', { page_title: 'Reports', event_type: 'Porfolio Tab', event_desc: 'Clicked on Portfolio Tab from Reports' })} />
                <Tab label='Engine off Wing' value="engOffWing" onClick={() => trackActivity('Tab Clicked', { page_title: 'Reports', event_type: ' Engine off Wing Tab', event_desc: 'Clicked on Engine off Wing Tab from Reports' })} />
                <Tab label='Custom Alerts' value="custom-alerts" onClick={() => trackActivity('Tab Clicked', { page_title: 'Reports', event_type: ' Custom Alerts', event_desc: 'Clicked on Custom Alerts Tab from Reports' })} />

            </Tabs>
        </Paper>
    )
}
export default withRouter(ReportsTab);
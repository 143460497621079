import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Checkbox, TablePagination,Tooltip,IconButton,Dialog } from "@material-ui/core";
import React, { useState, Fragment } from "react";
import ConfirmDialog from "./ConfirmDialog";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { SbTableHeadMain } from "../containers/SbTableHead";
import config from "../../../../config"
import { getLocalStorageInfo } from "../../../../utils";

const SbTable = ({ bulkOperation,heads, bulkFlag, noRecord, data, sort = "", sort_by = "", pagination = {}, onChangePage, onChangeRowsPerPage, createSortHandler, classNames, toggleBulkOps, currentRecordPerPage,bulkDeletefn }) => {
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
	const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

	return (
		<Fragment>
			<div id="AmpTable">
				<Table className="table-wrapper" id={smbcInstance ? "smbc_sb" : "sb"}>
					<TableHead>
						{!smbcInstance && <TableRow>
							{SbTableHeadMain.map((row, index) => (
								<TableCell colSpan={row.colspan} align="center" key={index}> {row.label} </TableCell>
							))}
						</TableRow>}
						<TableRow>
						{bulkOperation ? (
                                <TableCell>
                                    <Checkbox
                                        size="small"
                                        checked={bulkFlag}
                                        onChange={(e) => toggleBulkOps(e.target.checked)}
                                        name="bulk_operation"
                                        color="primary"
                                    />
                                    {bulkOperation?.ids?.length > 0 && <Tooltip title="Delete" arrow>
                                        <IconButton className="delete-icon">
                                            <DeleteOutlinedIcon onClick={handleClick} />({bulkOperation?.ids?.length})
                                        </IconButton>
                                    </Tooltip>}
                                </TableCell>
                            ) : null}
							{heads?.map((row, index) => (
								<TableCell
									key={index}
									align={row?.actionCell ? "right" : "left"}
									padding={"normal"}
									sortDirection={
										sort === row.id ? (sort_by ? sort_by : "asc") : false
									}
								>
									{/* {row.sortOption && noRecord === null ? (
										<TableSortLabel
											active={ sort === row.id}
											direction={sort_by && sort === row.id ? sort_by : "asc"}
											onClick={() => createSortHandler(row.id)}
										>
											{row.label}
										</TableSortLabel>
									) : (
										<span>{row.label}</span>
									)} */}
										{row.id  === "sb_ref_number" ? (
										<TableSortLabel
											active={ sort === "sb_ref_number" ? true : false}
											direction={sort_by && sort === "sb_ref_number"? sort_by : "desc"}
											onClick={() => createSortHandler("sb_ref_number")}
										>
											{row.label}
										</TableSortLabel>
									) : row.sortOption && noRecord === null ? (
										<TableSortLabel
											active={ sort === row.id}
											direction={sort_by && sort === row.id ? sort_by : "asc"}
											onClick={() => createSortHandler(row.id)}
										>
											{row.label}
										</TableSortLabel>
									) : (
										<span>{row.label}</span>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{noRecord ? (
							<TableRow id="table-no-records">
								<TableCell colSpan={bulkOperation ? heads.length + 1 : heads.length} align="center" > {noRecord} </TableCell>
							</TableRow>
						) : (
							data
						)}
					</TableBody>
				</Table>
			</div>
			{Object.keys(pagination).length && pagination.total > 0 ? (
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={pagination.total}
					rowsPerPage={currentRecordPerPage}
					page={pagination.page - 1}
					backIconButtonProps={{ "aria-label": "previous page" }}
					nextIconButtonProps={{ "aria-label": "next page" }}
					onChangePage={onChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
				/>
			) : null}
			   <Dialog open={open} onClose={handleClose} id="htDialog">
                <ConfirmDialog
                    handleClose={() => handleClose()}
                    handleDeleteRow={bulkDeletefn}
                    title={bulkOperation?.type === "bulk" ? "Do you really want to delete all the records?" :"Do you really want to delete selected records?"}
                />
            </Dialog>
		</Fragment>
	);
};

export default SbTable;

import { regexConstants } from "../../../../constants/regEx";

export const formValues = {
    asset_id: '',
    asset_type_id: '',
    part_number: '',
    serial_number: '',
    description: '',
    life_limited_part: '',
    tsn: '',
    csn: '',
    remarks: '',
    checklist: [],
    folder_uuid: ''
};

export const taskErrorCode = {
    serial_number: {
        0: "",
        1: "Please Enter Serial Number",
    },
    serial_number_obj: {
        required: true,
    },

};

import { React, useState, useEffect } from 'react'
import { Table, TableBody, Paper, Button, Grid, TextField, InputAdornment, TablePagination } from "@material-ui/core";
import { globalGetService, globalDeleteService } from '../../../utils/globalApiServices';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import config from "../../../config"
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { ConfigureListing, ConfigureTableHeader, AddEditConfigure } from '../components';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils';
import { EmptyCollection, PageLoader, DeletePopUp } from '../../../shared_elements';
import FilterComponent from '../../../shared_elements/components/filter_component';
import STableLoader from '../../../shared_elements/components/skeleton_loader/STableLoader';
import { configureFilterOptions } from './index';

function Configure({ match }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [skeltonLoader, setSkeltonLoader] = useState(false)
    const [filter, setFilter] = useState()
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [deletelist, setDeleteList] = useState({ modal: false, data: null })
    const [data, setData] = useState({ list: [], pagination: {} })
    const [addEditConfig, setAddEditConfig] = useState({ modal: false, data: null, type: '' })
    const [egineFamily, setEngineFamily] = useState([])

    useEffect(() => {
        getConfigList({ per_page: 20 }, 'skeltonLoader')
        getEngineFamily()
    }, [])

    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        getConfigList({ ...sortQuery, per_page: 20 }, 'pageLoader');
    }


    const getConfigList = (query, loaderType) => {
        loaderType === "skeltonLoader" ? setSkeltonLoader(true) : setLoading(true)
        globalGetService(`camo/disclemer_revision/`, query)
            .then((response) => {
                loaderType === "skeltonLoader" ? setSkeltonLoader(false) : setLoading(false)
                if (response.status === 200) {
                    setData(response.data)
                    setFilter(query)
                }
            })
    }

    const deleteConfigList = () => {
        setLoading(true)
        globalDeleteService(`camo/disclemer_revision/${deletelist?.data?.id}/`)
            .then((response) => {
                setLoading(false); setDeleteList({ modal: false }); getConfigList({ per_page: 20 })
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }

            })
    }

    const getEngineFamily = () => {
        globalGetService(`camo/engine_dropdown/`)
            .then((response) => {
                if (response.status === 200) {
                    setEngineFamily(response?.data?.engine_family)
                }
            })
    }

    const filterObj = Object.assign({}, filter, delete filter?.per_page, delete filter?.sort, delete filter?.sort_by, delete filter?.asset_type)

    let filterOptions = Object.assign({}, configureFilterOptions);
    filterOptions = {
        ...filterOptions,
        engine_family: {
            ...filterOptions.engine_family,
            options: egineFamily
        },
    }

    return (
        <section className='camo-fleet-module'>
            <h4 style={{color:'#3f51b5',marginBottom:'5px'}}>Disclaimer</h4>
            {skeltonLoader ? <STableLoader count={7} /> : <>
                <Paper square variant='outlined' style={{ padding: '0px 5px', marginBottom: "8px" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                variant='outlined'
                                fullWidth
                                margin='normal'
                                size='small'
                                placeholder='Search By Revision Number Engine Family Engine Type'
                                onChange={(e) => getConfigList({ search: e.target.value, per_page: 20 }, 'pageloader')}
                                InputProps={{ style: { fontSize: 14, marginTop: 5 }, endAdornment: <InputAdornment position="end"><SearchIcon color='primary' cursor="pointer" /></InputAdornment> }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div style={{ marginTop: "14px" }}>
                                <FilterComponent
                                    filter={filterObj}
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getConfigList({ ...applyFilter, page: 1, per_page: 20, }, 'pageLoader')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <ul className='list-inline' style={{ float: "right", margin: "revert", }}>
                                <li className='list-inline-item' style={{ marginRight: "5px" }}>
                                    <Button variant='contained' color='primary' size='small' onClick={() => setAddEditConfig({ modal: true, data: null, type: 'add' })}> + Add Configure</Button>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper variant='outlined' square style={{ padding: '5px 10px', marginBottom: "5px" }}>
                    <div style={{ height: `${window.innerHeight - 250}px`, overflow: 'auto' }}>
                        <Table className='mui-table-format' stickyHeader={true}>
                            <ConfigureTableHeader
                                createSortHandler={(sort) => createSortHandler(sort)}
                                sortInfo={sortInfo}
                            />
                            <TableBody>
                                {data?.results?.length > 0 && data?.results?.map((item, index) =>
                                    <ConfigureListing
                                        item={item}
                                        key={index}
                                        toggleModalFn={() => setAddEditConfig({ modal: true, data: item, type: 'edit' })}
                                        toggleDelteModalFn={() => setDeleteList({ modal: true, data: item })}
                                    />
                                )}
                            </TableBody>
                        </Table>
                        {!data?.results?.length ? <EmptyCollection title="No Records Founds" /> : null}

                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        component="div"
                        count={data?.pagination?.total}
                        rowsPerPage={parseInt(data?.pagination?.per_page)}
                        page={data?.pagination?.number_of_pages - 1}
                        backIconButtonProps={{ "aria-label": "previous page" }}
                        nextIconButtonProps={{ "aria-label": "next page" }}
                        onChangePage={(event, newPage) => getConfigList({ per_page: 20, page: newPage + 1 }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => getConfigList({ per_page: event.target.value, }, "pageLoader")}
                    />
                </Paper>
            </>}
            {addEditConfig.modal ?
                <AddEditConfigure
                    configData={addEditConfig.data}
                    modeType={addEditConfig.type}
                    toggleModalFn={() => setAddEditConfig({ modal: false })}
                    getResponseBack={() => getConfigList({ per_page: 20, asset_type: 2 })}
                /> : null
            }
            {deletelist.modal ?
                <DeletePopUp
                    title='Delete Configure '
                    deleteRecordFn={() => deleteConfigList()}
                    modal={() => setDeleteList({ modal: true })}
                    content='Do you really want to delete this record?'
                    toggleModalFn={() => setDeleteList({ modal: false })}
                /> : null

            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}

export default withRouter(Configure)

export const inventoryTableHead = [
  { id: "actions", label: "Action",},
  { id: "section_id", label: "Section", sortOption: true },
  { id: "description", label: "Description", sortOption: true  },
  { id: "iin", label: "IIN", sortOption: true },
  { id: "spec", label: "SPEC", sortOption: true },
  { id: "ipc_ppbu_fig", label: "IPC & PPBU FIG. / REF", sortOption: false, },
  { id: "quantity", label: "Quantity",sortOption: true,},
  { id: "part_number", label: "Part Number",sortOption: true,},
  { id: "serial_number", label: "Serial Number",sortOption: true,},
  { id: "installed", label: "Installed", sortOption: true },
  { id: "tsn", label: "TSN", sortOption: true },
  { id: "csn", label: "CSN", sortOption: true },
  { id: "tso", label: "TSO", sortOption: true },
  { id: "cso", label: "CSO", sortOption: true },
  { id: "condition", label: "Condition", sortOption: true },
  { id: "attachments", label: "Attachments" },
]


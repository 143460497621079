import React, { useState, Fragment, useEffect } from 'react'
import { ReportsTab } from '../components'
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Paper, Grid, Button } from '@material-ui/core';
import { ServiceableEngine, UnServiceableEngine } from '../components';
import { ExportMenu } from '../../../shared_elements';
import { globalExportService } from '../../../utils/globalApiServices';
import { downloadFileType } from '../../../utils';
import { PageLoader, EmptyCollection } from "../../../shared_elements"
function EngineOffwing() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [tab, settab] = useState('service')
	const [pageLoader, setPageLoader] = useState(false)


	const engineOffwingMasterReport = (extension) => {
		setPageLoader(true)
		globalExportService(`/camo/smbc_engine_off_wing_master_report/?download=${extension}`,)
			.then((response) => {
				setPageLoader(false)
				if (response.status === 500) {
					enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
				} else {
					downloadFileType(response.data, `Engine Off Wing Master Reports`, extension);
					enqueueSnackbar("Engine Off Wing Master Report Download Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
				}
			});
	}

	return (
		<>
			<ReportsTab
				tabIndex="engOffWing"
			/>
			<Paper square style={{ marginTop: '10px',marginBottom:'5px' }}>
				<Grid container spacing={2}>
					<Grid item >
						<Tabs indicatorColor='primary' value={tab} onChange={(event, newValue) => settab(newValue)}>
							<Tab label='Serviceable Engine' value="service" />
							<Tab label='UnServiceable Engine' value="nonService" />
						</Tabs>
					</Grid>
					<Grid item xs>
						<div style={{ marginTop: '10px' }}>
							<ExportMenu
								exportReportFn={(file) => engineOffwingMasterReport(file?.extension)}
								files={[{ title: 'EXCEL', extension: 'xls' }]}
								title='Engine Off Wing Master Export'
							/>
						</div>
					</Grid>

				</Grid>
			</Paper>
			{pageLoader ? <PageLoader /> : null}
			{
				tab === 'service' ? <ServiceableEngine /> : <UnServiceableEngine />
			}
		</>
	)
}

export default withRouter(EngineOffwing)
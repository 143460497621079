import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, FormControl, FormLabel, FormControlLabel, Radio, Dialog, TextareaAutosize, Tooltip } from "@material-ui/core"
import RadioGroup from "@material-ui/core/RadioGroup"
import CloseIcon from "@material-ui/icons/Close"
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/ShopvisitTaskFormData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment"
import axios from 'axios';
import { validationMess } from "../../../../utils";
import MomentUtils from "@date-io/moment"
import { backendDateFormat, dayMonthDateFormat } from "../../../../constants"
import { addSvFormApi, deleteAdAttachment, resetTslsv_CslsvApi } from "../apiServices"
import { regexConstants } from "../../../../constants/regEx"
import { checkApiStatus, format_Commas } from "../../../../utils"
import { globalPutService } from "../../../../utils/globalApiServices"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { assetType, imgStoragePath, specificKey } from "../../../../constants";
import config from "../../../../config";
import Switch from '@material-ui/core/Switch';

class SvTaskForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: {},
            data: formValues,
            regex: "^[0-9]+$",
            attachments: [],
            newUploadedAttachments: [],
            btnStatus: "",
            open: false,
            deleteAttachmentId: 0,
            showSuccessUpload: false,
            linkFile: false,
            uploadFile: false,
            pagLoader: false
        }
        this.addSvFormApi = addSvFormApi.bind(this)
        this.resetTslsv_CslsvApi = resetTslsv_CslsvApi.bind(this)

    }

    componentDidMount = () => {
        if (this.props.editFormId) {
            this.fillEditData()
        }
    }
    handleOpenFile = () => {
        this.setState({ linkFile: true })
    };

    handleCloseFile = () => {
        this.setState({ linkFile: false })
    };
    handleOpenUploadFile = () => {
        this.setState({ uploadFile: true })
    };
    handleUploadFileCloseFile = () => {
        this.setState({ uploadFile: false })
    };

    onFieldChange = (keyParam, value, item) => {
        if (keyParam === "sv_reset_tslsv_cslsv") {
            if (this.props.actionType === "add") {
                this.setState((prevState) => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        [keyParam]: !value,
                    },
                }))
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        [keyParam]: !value,
                    },
                }))
                resetTslsv_CslsvApi(this.props.props, item)
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [keyParam]: value,
                },
            }))
        }
    }

    onRestErrorKey = (keyParam) => {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                [keyParam]: "",
            },
        }))
    }
    handleSubmit = (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        validationInputs = {
            ...validationInputs,
            removal_date: taskErrorCode["removal_date"][fieldValidation({ ...taskErrorCode["removal_date_obj"], fieldval: data.removal_date, })],
            removal_reason: taskErrorCode["removal_reason"][fieldValidation({ ...taskErrorCode["removal_reason_obj"], fieldval: data.removal_reason, })],
            tsn_at_removal: taskErrorCode["tsn_at_removal"][fieldValidation({ ...taskErrorCode["tsn_at_removal_obj"], fieldval: data.tsn_at_removal, })],
            csn_at_removal: taskErrorCode["csn_at_removal"][fieldValidation({ ...taskErrorCode["csn_at_removal_obj"], fieldval: data.csn_at_removal, })],
            sv_completion_date: taskErrorCode["sv_completion_date"][fieldValidation({ ...taskErrorCode["sv_completion_date_obj"], fieldval: data.sv_completion_date, })],
            sv_type: taskErrorCode["sv_type"][fieldValidation({ ...taskErrorCode["sv_type_obj"], fieldval: data.sv_type, })],
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
            const props = this.props.props
            let payload = Object.assign({}, data)
            payload = {
                ...payload,
                asset_id: props.match.params.asset,
                asset_type_id: props.match.params.assetType,
                sv_type: payload.sv_type ? payload.sv_type : null,
                tsn_at_removal: payload.tsn_at_removal ? payload.tsn_at_removal : null,
                csn_at_removal: payload.csn_at_removal ? payload.csn_at_removal : null,
                min_build_standard: payload.min_build_standard ? payload.min_build_standard : null,
                target_on_wing_hours: payload.target_on_wing_hours ? payload.target_on_wing_hours : null,
                target_on_wing_cycle: payload.target_on_wing_cycle ? payload.target_on_wing_cycle : null,
                induction_date: payload.induction_date ? payload.induction_date : null,
                expected_date: payload.expected_date ? payload.expected_date : null,

            }
            this.setState({ pagLoader: true })
            addSvFormApi(payload, props, this.props.addForm).then(async (response) => {
                const uploadId = response.data?.data?.id
                if (uploadId) {
                    await this.uploadFileTest(uploadId, props, false)
                }
                if (uploadId && this.state.btnStatus !== "addAnother") {
                    this.props.closeAddForm()
                } else {
                    return false
                }
                this.setState({ pagLoader: false })
            })

        } else {
            this.setState({ error: validationInputs })
        }
    }
    fillEditData = () => {
        const updateId = this.props.editFormId
        this.setState({ attachments: this.props.attachments })
        if (updateId === undefined || updateId === null) {
            this.setState({
                data: {},
            })
        } else {
            this.setState({
                data: this.props.editFormData,
            })
        }
    }
    updateData = async (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        validationInputs = {
            ...validationInputs,
            removal_date: taskErrorCode["removal_date"][fieldValidation({ ...taskErrorCode["removal_date_obj"], fieldval: data.removal_date, })],
            removal_reason: taskErrorCode["removal_reason"][fieldValidation({ ...taskErrorCode["removal_reason_obj"], fieldval: data.removal_reason, })],
            tsn_at_removal: taskErrorCode["tsn_at_removal"][fieldValidation({ ...taskErrorCode["tsn_at_removal_obj"], fieldval: data.tsn_at_removal, })],
            csn_at_removal: taskErrorCode["csn_at_removal"][fieldValidation({ ...taskErrorCode["csn_at_removal_obj"], fieldval: data.csn_at_removal, })],
            sv_completion_date: taskErrorCode["sv_completion_date"][fieldValidation({ ...taskErrorCode["sv_completion_date_obj"], fieldval: data.sv_completion_date, })],
            sv_type: taskErrorCode["sv_type"][fieldValidation({ ...taskErrorCode["sv_type_obj"], fieldval: data.sv_type, })],
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
            let payload = Object.assign({}, data)
            const props = this.props.props
            payload = {
                ...payload,
                asset_id: props.match.params.asset,
                asset_type_id: props.match.params.assetType,
                sv_type: payload?.sv_type || null,
                tsn_at_removal: payload?.tsn_at_removal || null,
                csn_at_removal: payload?.csn_at_removal || null,
                min_build_standard: payload.min_build_standard ? payload.min_build_standard : null,
                target_on_wing_hours: payload.target_on_wing_hours ? payload.target_on_wing_hours : null,
                target_on_wing_cycle: payload.target_on_wing_cycle ? payload.target_on_wing_cycle : null,
                induction_date: payload.induction_date ? payload.induction_date : null,
                expected_date: payload.expected_date ? payload.expected_date : null,
            }
            const updateId = this.props.editFormId
            this.setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    data: this.props.editFormData,
                },
            }))
            if (updateId) {
                await this.uploadFileTest(updateId, props, true)
            }
            await this.props.updateFormData(updateId, payload, props, this.props.addForm, this.props.closeAddForm)
            // this.props.closeAddForm()
        } else {
            this.setState({ error: validationInputs })
        }
    }

    uploadFileTest = async (uploadId, props, isUpdate) => {
        if (this.state.newUploadedAttachments.length > 0) {
            let newAttachmentObj = []
            let formdata = new FormData()
            const module_type_id = 9
            Object.keys(this.state.newUploadedAttachments).map((key, index) => {
                newAttachmentObj.push({
                    file: this.state.newUploadedAttachments[key],
                    file_name: this.state.newUploadedAttachments[key].name,
                    file_type: this.state.newUploadedAttachments[key].type,
                    module_type_id,
                    module_id: uploadId,
                    asset_id: this.props.props.match.params.asset,
                    asset_type_id: this.props.props.match.params.assetType

                })
                formdata.append(
                    "[" + key + "]file",
                    this.state.newUploadedAttachments[key]
                )
                formdata.append(
                    "[" + key + "]file_type",
                    this.state.newUploadedAttachments[key].type
                )
                formdata.append(
                    "[" + key + "]file_name",
                    this.state.newUploadedAttachments[key].name
                )
                formdata.append("[" + key + "]module_id", uploadId)
                formdata.append("[" + key + "]module_type_id", module_type_id)
                formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
                formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
            })
            this.props.fileUploadData(uploadId, formdata, props, true)
            if (this.state.btnStatus === "addAnother") {
                this.resetBothAttachmentArray()
            }
            if (!isUpdate) {
                this.onSubmitFileLink(uploadId)
                this.props.fileUploadData(uploadId, null, props, false)
            }
        } else {
            if (this.state.btnStatus === "addAnother") {
                this.setState((prevState) => ({
                    ...prevState,
                    data: formValues,
                    attachments: [],
                }))
                document.getElementById("asset-form").reset()
                this.props.fileUploadData(uploadId, null, props, false)
            } else {
                if (!isUpdate) {
                    this.onSubmitFileLink(uploadId)
                    this.props.fileUploadData(uploadId, null, props, false)
                }
            }
        }
    }

    resetBothAttachmentArray = () => {
        this.setState((prevState) => ({
            ...prevState,
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            open: false,
            deleteAttachmentId: 0,
        }))
        document.getElementById("asset-form").reset()
    }

    onUpload = (files) => {
        const chosenFiles = Array.prototype.slice.call(files)
        for (let i = 0; i < chosenFiles.length; i++) {
            if (chosenFiles[i].size > 52428800) {
                this.props.props.enqueueSnackbar(
                    `${chosenFiles[i].name} Maximum file size should be 50MB.`,
                    {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }
                )
                delete chosenFiles[i]
                continue
            } else {
                this.handleUploadFiles(chosenFiles)
                // document.querySelector(".MuiDialog-paper").scrollTop = 800;
                this.setState({
                    showSuccessUpload: true,
                })
                setTimeout(() => {
                    if (this.state.attachments) {
                        this.setState({
                            showSuccessUpload: false,
                        })
                    }
                }, 2500)
            }
        }
    }

    handleUploadFiles = (files) => {
        const uploaded = [...this.state.newUploadedAttachments]
        const existingAttachments = [...this.state.attachments]
        files.some((file) => {
            uploaded.push(file)
        })
        files.map((file) => {
            var objc = {
                id: 0,
                module_id: 0,
                module_type_id: 9,
                file_name: file.name,
                file_type: file.type,
                file: "",
                name: file.name,
                asset_id: this.props.props.match.params.asset,
                asset_type_id: this.props.props.match.params.assetType
            }
            existingAttachments.push(objc)
        })

        this.setState({
            newUploadedAttachments: uploaded,
            attachments: existingAttachments,
        })
    }

    handleClickAttachment = (e, id) => {
        this.setState({ open: true })
        this.setState({ deleteAttachmentId: id })
    }

    handleCloseAttachment = (e) => {
        this.setState({ open: false })
        this.setState({ deleteAttachmentId: 0 })
    }

    deleteAttachmentFile = async (id, file) => {
        if (id !== undefined && id != 0) {
            const props = this.props.props
            deleteAdAttachment(id, props)
            var array = [...this.state.attachments]
            array = array.filter((item) => item.id !== id)
            this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
        } else {
            this.onRemoveCamoFile(file)
        }
    }

    removeAttachment = (e, obj) => {
        var array = [...this.state.attachments]
        var newuploadarray = [...this.state.newUploadedAttachments]

        var filteredArray = []

        newuploadarray.map((file) => {
            if (file.name == obj.file_name && file.type == obj.file_type) {
            } else {
                filteredArray.push(file)
            }
        })
        // Set the state with the new filtered array
        array = array.filter((item) => item !== obj)
        this.setState({
            newUploadedAttachments: filteredArray,
            attachments: array,
        })

    }

    saveAndAddAnother = async (e) => {
        await this.setState({ btnStatus: "addAnother" })
        await this.handleSubmit(e)
    }

    addFormData = async (e) => {
        await this.setState({ btnStatus: "add" })
        this.handleSubmit(e)
    }

    getResponseBack = (file) => {
        const { data, } = this.state
        this.setState({
            data: {
                ...data,
                checklist: file,
            },
        });
    }
    listResponseBack = (file) => {
        if (this.props.actionType === "add" || 'edit') {
            return null
        } else {
            this.props.getResponseBack(file)
        }
    }
    downloadFile = (file) => {
        axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
            })
    }
    onUnlinkCheckListFile = (checklist, fileId) => {
        const { data } = this.state;
        let payload = {};
        payload.delete = true;
        payload.folder_ids = checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
        payload.file_ids = checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
        globalPutService(`camo/shop/${this.props.editFormId}/document-checklists/`, payload)
            .then((response) => {
                if (checkApiStatus(response)) {
                    const updatedChecklist = { ...checklist };
                    updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                    updatedChecklist.folders = updatedChecklist?.folders.filter((file) => file.id !== fileId);
                    this.setState({
                        data: {
                            ...data,
                            checklist: updatedChecklist,
                        },
                    });
                }
            });
    }
    onRemoveFile = (file) => {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                checklist: data.checklist.filter(item => item.name !== file.name)
            }
        });
    }
    onRemoveCamoFile = (file) => {
        const { attachments } = this.state;
        this.setState({
            attachments: attachments.filter(item => item.file_name !== file.file_name)
        });
    }

    onLinkFile = (file, uuid, type) => {
        if (type == "link" || uuid) {
            const { data } = this.state;
            const folderUUID = [...data.folder_uuid, uuid];
            const updatedChecklist = [...data.checklist, file];

            let uuidData = [];
            let output = [];

            for (const arr of folderUUID) {
                uuidData = uuidData.concat(arr);
            }

            for (const arr of updatedChecklist) {
                output = output.concat(arr);
            }
            this.setState({
                data: {
                    ...data,
                    checklist: output,
                    folder_uuid: uuidData
                },
            });
        } else {
            this.onUpload(file)
        }

    }

    onSubmitFileLink = (id) => {
        const { data } = this.state;
        if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
            let payload = {};
            if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            } else if (data?.checklist.some(item => item.type === 'folder')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
            } else {
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            }
            globalPutService(`camo/shop/${id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                        // this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0 && data.folder_uuid) {
            let formData = new FormData();
            formData.append('folder_uuid', data.folder_uuid);
            for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
                formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
            }
            globalPutService(`camo/shop/${id}/document-checklists/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
    }
    render() {
        const { data, error, attachments, pagLoader } = this.state
        return (
            <Fragment>
                <div id="AmpTaskForm">
                    <div id="HtTaskForm">
                        <DialogTitle>
                            {this.props.formTitle}
                            <CloseIcon
                                onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
                                className="close-icon"
                            />
                        </DialogTitle>
                        <form
                            id="asset-form"
                            onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
                        >
                            <DialogContent>
                                <Grid spacing={1} container>
                                    <Grid item xs={3} className="input-calender">
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                required
                                                id="removal_date"
                                                label="Removal Date"
                                                format={dayMonthDateFormat}
                                                inputVariant="outlined"
                                                fullWidth
                                                disableFuture
                                                onPaste={(event) => event.preventDefault()}
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, "years")}
                                                value={data.removal_date}
                                                error={error.removal_date ? true : false}
                                                helperText={error.removal_date ? error.removal_date : ""}
                                                onFocus={() => this.onRestErrorKey("removal_date")}
                                                onChange={(data, value) => {
                                                    this.onFieldChange("removal_date", moment(data).format(backendDateFormat))
                                                    this.onRestErrorKey("removal_date")
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            required
                                            variant="outlined"
                                            label="Removal Reason"
                                            name="removal_reason"
                                            id="removal_reason"
                                            inputProps={{ maxLength: 100 }}
                                            value={data.removal_reason}
                                            InputLabelProps={{ shrink: true }}
                                            error={error.removal_reason ? true : false}
                                            helperText={error.removal_reason ? error.removal_reason : ""}
                                            onChange={(e) => { this.onFieldChange("removal_reason", e.target.value); this.onRestErrorKey("removal_reason") }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            required
                                            variant="outlined"
                                            label={
                                                <span style={{ verticalAlign: 'super' }}>
                                                    TSN at Removal
                                                    <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
                                                        <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                    </Tooltip>
                                                </span>
                                            }
                                            id="tsn_at_removal"
                                            inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.tsn_at_removal) && 10 }}
                                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                            value={regexConstants.alphanumespcespclchar.test(data.tsn_at_removal) ? data.tsn_at_removal : format_Commas(data?.tsn_at_removal.replace(/[^0-9.]/g, ''))}
                                            error={error.tsn_at_removal ? true : false}
                                            helperText={error.tsn_at_removal ? error.tsn_at_removal : ""}
                                            onChange={(e) => {
                                                const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
                                                const numericValue = value.replace(/[^\d.]/g, '');
                                                const dotCount = (numericValue.match(/\./g) || []).length;
                                                const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                                if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                    this.onFieldChange("tsn_at_removal", e.target.value);
                                                    this.setState({ error: { ...error, tsn_at_removal: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('TSN at Removal', true) }, })
                                                } else if (isValid || value === "") {
                                                    this.onFieldChange("tsn_at_removal", numericValue);
                                                    this.setState({ error: { ...error, tsn_at_removal: regexConstants.numberWithDot.test(numericValue) || e.target.value === (null || undefined || "") ? "" : e.target.value === null ? "" : "" } })
                                                    setTimeout(() => {
                                                        this.onRestErrorKey("tsn_at_removal");
                                                    }, 9000)
                                                }
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            required
                                            variant="outlined"
                                            label={
                                                <span style={{ verticalAlign: 'super' }}>
                                                    CSN at Removal
                                                    <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999</span>} arrow>
                                                        <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                    </Tooltip>
                                                </span>
                                            }
                                            id="csn_at_removal"
                                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                            inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.csn_at_removal) && 9 }}
                                            error={error.csn_at_removal ? true : false}
                                            helperText={error.csn_at_removal ? error.csn_at_removal : ""}
                                            value={regexConstants.alphanumespcespclchar.test(data.csn_at_removal) ? data.csn_at_removal : format_Commas(data?.csn_at_removal.toString().replace(/[^0-9.]/g, ''))}
                                            onChange={(e) => {
                                                if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                    this.onFieldChange("csn_at_removal", e.target.value);
                                                    this.setState({ error: { ...error, csn_at_removal: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('CSN at Removal', false) }, })
                                                } else {
                                                    this.setState({ error: { ...error, csn_at_removal: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : "" } })
                                                    e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && this.onFieldChange('csn_at_removal', e.target.value); this.onRestErrorKey("csn_at_removal")
                                                }
                                            }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid spacing={1} container>
                                    <Grid item xs={3} className="input-calender">
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                required
                                                id="sv_completion_date"
                                                label="Shop Visit Completion Date"
                                                format={dayMonthDateFormat}
                                                inputVariant="outlined"
                                                fullWidth
                                                disableFuture
                                                onPaste={(event) => event.preventDefault()}
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, "years")}
                                                value={data.sv_completion_date}
                                                error={error.sv_completion_date ? true : false}
                                                helperText={error.sv_completion_date ? error.sv_completion_date : ""}
                                                onFocus={() => this.onRestErrorKey("sv_completion_date")}
                                                onChange={(data, value) => {
                                                    this.onFieldChange("sv_completion_date", moment(data).format(backendDateFormat))
                                                    this.onRestErrorKey("sv_completion_date")
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label="Shop Visit Location /MRO"
                                            name="sv_location_mro"
                                            id="sv_location_mro"
                                            value={data.sv_location_mro}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={(e) => this.onFieldChange("sv_location_mro", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label="Shop Visit Workscope"
                                            name="sv_workscope"
                                            id="sv_workscope"
                                            InputLabelProps={{ shrink: true }}
                                            value={data.sv_workscope}
                                            inputProps={{ maxLength: 100 }}
                                            onChange={(e) => this.onFieldChange("sv_workscope", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Min Build Standard"
                                            margin='normal'
                                            variant='outlined'
                                            fullWidth
                                            value={data.min_build_standard}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => this.onFieldChange("min_build_standard", e.target.value)}
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid spacing={1} container>
                                    <Grid item xs={3} className="input-calender">
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                id="induction_date"
                                                label="Induction Date"
                                                format={dayMonthDateFormat}
                                                inputVariant="outlined"
                                                fullWidth
                                                disabled={!data.removal_date}
                                                onPaste={(event) => event.preventDefault()}
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment(data.removal_date)}
                                                value={data.induction_date}
                                                error={error.induction_date ? true : false}
                                                helperText={error.induction_date ? error.induction_date : ""}
                                                onFocus={() => this.onRestErrorKey("induction_date")}
                                                onChange={(data, value) => {
                                                    this.onFieldChange("induction_date", moment(data).format(backendDateFormat))
                                                    this.onRestErrorKey("induction_date")
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3} className="input-calender">
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                id="expected_date"
                                                label="Expected Date"
                                                format={dayMonthDateFormat}
                                                inputVariant="outlined"
                                                fullWidth
                                                disablePast
                                                onPaste={(event) => event.preventDefault()}
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment(new Date())}
                                                value={data.expected_date}
                                                error={error.expected_date ? true : false}
                                                helperText={error.expected_date ? error.induction_date : ""}
                                                onFocus={() => this.onRestErrorKey("expected_date")}
                                                onChange={(data, value) => {
                                                    this.onFieldChange("expected_date", moment(data).format(backendDateFormat))
                                                    this.onRestErrorKey("expected_date")
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            name="target_on_wing_hours"
                                            id="target_on_wing_hours"
                                            label="Target On Wing Hours"
                                            InputLabelProps={{ shrink: true }}
                                            value={data.target_on_wing_hours || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const numericValue = value.replace(/[^\d.]/g, '');
                                                const dotCount = (numericValue.match(/\./g) || []).length;
                                                const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                                if (isValid || value === "") {
                                                    this.onFieldChange("target_on_wing_hours", numericValue);
                                                }
                                            }}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            name="target_on_wing_cycle"
                                            label="Target On Wing Cycle"
                                            id="target_on_wing_cycle"
                                            inputProps={{ maxLength: 5 }}
                                            InputLabelProps={{ shrink: true }}
                                            value={data.target_on_wing_cycle || ''}
                                            onChange={(e) => (e.target.value || e.target.value === '') && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('target_on_wing_cycle', e.target.value)}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid spacing={1} container>
                                    <Grid item xs={3}>
                                        <h5 style={{ color: '#0000008a' }}>Click Toggle to Reset TSLSV and CSLSV</h5>
                                        <Switch
                                            checked={data?.sv_reset_tslsv_cslsv}
                                            onChange={() => this.onFieldChange("sv_reset_tslsv_cslsv", data?.sv_reset_tslsv_cslsv, data?.id)}
                                            color="primary"
                                            id="sv_reset_tslsv_cslsv"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <label>Shop Visit Type <span style={{ color: "red" }}> *</span> </label>
                                        <FormControl id="d-block" style={{ width: 'max-content' }}>
                                            <FormLabel component="legend"
                                                error={error.sv_type ? true : false}
                                            ></FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="sv_type"
                                                name="sv_type"
                                                defaultValue="top"
                                                value={data.sv_type}
                                                onChange={(e, value) => { this.onFieldChange("sv_type", e.target.value); this.setState({ error: { ...error, "sv_type": '' } }) }}
                                            >
                                                <FormControlLabel
                                                    value="PRSV"
                                                    control={<Radio size="small" color="primary" />}
                                                    label="PRSV"
                                                />
                                                <FormControlLabel
                                                    value="Repair"
                                                    control={<Radio size="small" color="primary" />}
                                                    label="Repair"
                                                />
                                                <FormControlLabel
                                                    value="Overhaul"
                                                    control={<Radio size="small" color="primary" />}
                                                    label="Overhaul"
                                                />
                                            </RadioGroup>
                                            {error.sv_type && (
                                                <FormLabel component="faa_easa"> <span style={{ color: "red" }}>{error.sv_type}</span> </FormLabel>
                                            )}
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <div className="border-grey"></div>
                                <Grid spacing={1} container>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Remarks"
                                            margin='normal'
                                            variant='outlined'
                                            fullWidth
                                            value={data.remarks}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => this.onFieldChange("remarks", e.target.value)}
                                            rows={3}
                                            multiline
                                            inputProps={{ maxLength: 250 }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
                                            <ul className="list-inline spacing-list">
                                                <li className="list-inline-item" onClick={this.handleOpenUploadFile}>
                                                    <span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
                                                </li>
                                                <li className="list-inline-item" onClick={this.handleOpenFile}>
                                                    <span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid id="data-container" spacing={1} container>
                                    <Grid spacing={1} container>
                                        {this.props.actionType === 'add' ?
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.files?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {(data.checklist?.folders?.length > 0) &&
                                                        data.checklist?.folders.map((file) => {
                                                            return (
                                                                <li className='list-inline-item' key={file.id}>
                                                                    <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                                        <a href={`${config.domain.records}${assetType[this.props.props?.match.params.assetType]}/${file?.asset_slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                                    </Tooltip>
                                                                    <span className='file-name'>{file.name}</span>
                                                                    <span className='file-remove'>
                                                                        <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                                    </span>
                                                                </li>
                                                            );
                                                        })}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id, attachments)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className="actions-btn">
                                <Button
                                    disabled={pagLoader}
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
                                >
                                    {this.props.actionType === "add" ? "Add" : "Update"}
                                </Button>

                                {this.props.actionType === "add" ? (
                                    <Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
                                ) : (
                                    ""
                                )}
                                <Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}  > Close </Button>
                            </DialogActions>
                            <Dialog
                                open={this.state.open}
                                onClose={this.handleCloseAttachment}
                                id="htDialog"
                            >
                            </Dialog>
                            <Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
                                <LinkFiles
                                    handleClose={() => this.handleCloseFile()}
                                    uuid={this.props?.uuid?.uuid}
                                    editFormId={this.props.editFormId}
                                    getResponseBack={(file) => this.getResponseBack(file)}
                                    listResponseBack={(file) => this.listResponseBack(file)}
                                    actionType={this.props.actionType}
                                    onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
                                    modType="shop"
                                    last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
                                    uploadFileInfo={data}
                                    attachments={attachments}
                                    type="link"
                                />
                            </Dialog>
                            <Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
                                <UploadFile
                                    handleClose={() => this.handleUploadFileCloseFile()}
                                    uuid={this.props?.uuid?.uuid}
                                    editFormId={this.props.editFormId}
                                    getResponseBack={(file) => this.getResponseBack(file)}
                                    listResponseBack={(file) => this.listResponseBack(file)}
                                    actionType={this.props.actionType}
                                    onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
                                    modType="shop"
                                    uploadFileInfo={data}
                                    last_used_folder_uuid={this.props.last_used_folder_uuid}
                                    onUpload={(file) => this.onUpload(file)}
                                    type="upload"
                                    checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
                                    checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
                                />
                            </Dialog>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SvTaskForm
export const SvTableHead = [
	{ id: "actions", label: "Actions", sortOption: false },
	{ id: "removal_date", label: "Removal Date", sortOption: true },
	{ id: "removal_reason", label: "Removal Reason", sortOption: true },
	{ id: "tsn_at_removal", label: "TSN at Removal", sortOption: true },
	{ id: "csn_at_removal", label: "CSN at Removal", sortOption: true },
	{ id: "sv_completion_date", label: "Shop Visit Completion Date", sortOption: true },
	{ id: "sv_location_mro", label: "Shop Visit  Location /MRO", sortOption: true },
	{ id: "sv_workscope", label: "Shop Visit  Workscope", sortOption: true },
	{ id: "sv_type", label: "Shop Visit  Type", sortOption: true },
	{ id: "attachments", label: "Attachments" },
];

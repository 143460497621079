import React from "react";
import { Provider } from "react-redux";
import { withOrientationChange } from "react-device-detect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Zoom, Fab, useScrollTrigger, makeStyles } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import configureStore from "./store/configureStore";
import { authRoutes } from "./application/auth/authRoutes";
import { assetRoutes } from "./application/home/assetRoutes";
import { camoRoutes } from "./application/camo/camoRoutes";
import { deletedRoutes } from "./application/camo/Deleted Section/Routes";
import { dashboardRoutes } from "./application/dashboard/dashboardRoutes";
import { reportsRoutes } from "./application/fleet/routes";
import PrivateRoute from "./hocs/PrivateRoute";
import { PageNotFound } from "./shared_elements";
const store = configureStore();
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
function ScrollTop(props) {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.root}
        style={{ bottom: "18px" }}
      >
        {children}
      </div>
    </Zoom>
  );
}
const App = ({ isLandscape, isPortrait }) => {
  return (
    <Provider store={store}>
      <div id="back-to-top-anchor" />
      <SnackbarProvider maxSnack={3}>
        <Router>
          <Switch>
            {authRoutes.map(({ path, component, key }, index) => (
              <Route exact={true} path={path} component={component} key={key} />
            ))}
            {[...dashboardRoutes, ...assetRoutes, ...camoRoutes,...reportsRoutes,...deletedRoutes].map(
              ({ path, component, key }, index) => (
                <PrivateRoute
                  exact
                  path={path}
                  component={component}
                  key={key}
                />
              )
            )}
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Router>
      </SnackbarProvider>
      <ScrollTop>
        <Fab
          color="secondary"
          size="small"
          aria-label="scroll back to top"
          style={{ background: "#2b4ca7" }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Provider>
  );
};
export default withOrientationChange(App);

import React, { Fragment, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import { ReportsTab } from '../components'
import { Grid, Paper, Button, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Chip } from '@material-ui/core';
import { customAlrtFilterOps } from './';
import { globalDeleteService, globalGetService } from '../../../utils/globalApiServices';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils';
import { DeletePopUp, EmptyCollection, PageLoader } from '../../../shared_elements';
import moment from 'moment';
import { Delete, Edit } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FilterListIcon from "@material-ui/icons/FilterList"
import TimelineIcon from '@material-ui/icons/Timeline';
import { getAllAssetListAc } from '../../../shared_elements/actionCreators';
import { useDispatch, } from 'react-redux';
import { AddEditAlerts, RevisionList } from '../components';
import { displayDateFormat } from '../../../constants';
import RevisionHistory from '../../../shared_elements/components/RevisionHistory';
import { STableLoader } from '../../../shared_elements/loaders';
import FilterComponent from '../../../shared_elements/components/filter_component';
import Pagination from '../../../shared_elements/components/Pagination';

export default function CustomAlerts() {
    const [filter, setFilter] = useState({});
    const [alertsList, setAlertsList] = useState([]);
    const [alertModal, setAlertModal] = useState({ flag: false });
    const [deleteAlert, setDeleteAlert] = useState({});
    const [pageloader, setPageloader] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [revisionList, setRevisionList] = useState({ flag: false });
    const [usersList, setUsersList] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllAssetListAc())
        getCustomAlertsList()
        getUsersListFn()
    }, [])



    const getCustomAlertsList = (query, loaderType) => {
        if (loaderType === 'pageLoader') {
            setPageloader(true)
        }
        else setSkeletonLoader(true)
        globalGetService(`camo/reports/custom-alerts/`, query)
            .then(response => {
                if (loaderType === 'pageLoader') {
                    setPageloader(false)
                } else {
                    setSkeletonLoader(false)
                }
                setAlertsList(response.data.data)
                if (query) {
                    delete query?.per_page
                    delete query?.page
                    delete query?.total
                    setFilter(query)
                }
            })
    }
  

    const setEditAlerts = (item, mode) => {
        setAlertModal({ flag: true, data: item, mode: mode })
    }
    const setRevionAlert = (item, query) => {
        setPageloader(true)
        globalGetService(`camo/reports/custom-alerts/${item?.id}/history/`, query)
            .then(response => {
                setPageloader(false)
                if (checkApiStatus(response)) {
                    setRevisionList({ flag: true, data: response?.data.data, item: item })
                }
            })
    }
    const getUsersListFn = () => {
        globalGetService('camo/camo_user_list/', { application: 19 })
            .then(response => {
                if (checkApiStatus(response)) {
                    // let responseData = response.data?.data?.map(item => {return(
                    //    { ...item, label:`${item?.name || ''} (${item?.email})`}
                    // )})
                    setUsersList(response.data.data)
                }
            })
    }
    const deleteAlertFn = (id) => {
        setPageloader(true)
        globalDeleteService(`/camo/reports/custom-alerts/${id}`)
            .then(response => {
                setPageloader(false)
                if (checkApiStatus(response)) {
                    setDeleteAlert({ flag: false })
                    getCustomAlertsList()
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }


    return (
        <section className="fleets-management">
            <ReportsTab
                tabIndex="custom-alerts"
            />
            {skeletonLoader ? <STableLoader count={9} /> :
                <Paper>
                    <Grid container spacing={1}>
                        <Grid item sm={9}>
                            <FilterComponent
                                icon={<FilterListIcon style={{ margin: '5px 0px 5px 10px' }} />}
                                filter={filter}
                                filterMenu={Object.assign({}, customAlrtFilterOps)}
                                getResponseBack={(applyFilter) => { getCustomAlertsList({ ...applyFilter, page: 1, per_page: alertsList?.pagination?.per_page }, 'pageLoader'); }}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <div style={{ textAlign: 'right', paddingTop: '2px', paddingRight: '5px' }}>
                                <Button style={{ cursor: "pointer", marginBottom: '8px', position: 'relative' }} onClick={() => setAlertModal({ flag: true, data: { alert_frequency_type: 0 } })} color="primary" size="small" variant="contained">
                                    Add Custom Alerts
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ height: `${window.innerHeight - 230}px`, overflow: 'auto' }} >
                        <Table className='mui-table-format' stickyHeader={true}>
                            <TableHead>
                                <TableCell>Activity Type</TableCell>
                                <TableCell>Notification Preference</TableCell>
                                <TableCell>Alert Valid Till</TableCell>
                                <TableCell >Users</TableCell>
                                <TableCell>Created By</TableCell>
                                <TableCell width="100">Actions</TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    alertsList?.list?.length > 0 && alertsList?.list?.map((item, index) =>
                                        <CustomList
                                            setDeleteAlert={(id) => setDeleteAlert({ flag: true, id: id })}
                                            item={item}
                                            setEditAlerts={setEditAlerts}
                                            setRevionAlert={setRevionAlert}
                                        />
                                    )
                                }
                            </TableBody>
                        </Table>
                        {
                            alertsList?.list < 1 && !pageloader ?
                                <EmptyCollection title={'No records found'} /> : null
                        }
                    </div>
                    <Pagination
                        pagination={alertsList?.pagination}
                        onChangePage={(event, newPage) => getCustomAlertsList({ page: newPage + 1, per_page: alertsList?.pagination?.per_page }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => getCustomAlertsList({ page: 1, per_page: event.target.value }, 'pageLoader')}
                    />
                </Paper>}
            {
                alertModal.flag ?
                    <AddEditAlerts
                        getResponseBack={() => { getCustomAlertsList({ ...filter, ...alertsList?.pagination }); setAlertModal({ flag: false }) }}
                        usersList={usersList}
                        alertModal={alertModal}
                        toggleModalFn={() => setAlertModal({ flag: false })}
                    />
                    : null
            }
            {deleteAlert.flag ?
                <DeletePopUp
                    modal={deleteAlert.flag}
                    toggleModalFn={() => setDeleteAlert({ flag: false, data: null })}
                    title="Delete Custom Alert"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => deleteAlertFn(deleteAlert?.id)}
                /> : null
            }{
                revisionList?.flag ?
                    <RevisionList
                        getResponseBack={setRevionAlert}
                        revisionList={revisionList}
                        toggleModalFn={() => setRevisionList({ flag: false })}
                    /> : null
            }
            {
                pageloader ? <PageLoader /> : null
            }
        </section>
    )
}
const CustomList = ({ item, setEditAlerts, setDeleteAlert, setRevionAlert }) => {
    const [lessMore, setLessMore] = useState({});
    const getOrdinal = (number) => {
        if (10 <= number % 100 && number % 100 <= 20) {
            return 'th';
        } else {
            const suffix = { 1: 'st', 2: 'nd', 3: 'rd' }[number % 10] || 'th';
            return suffix;
        }
    };
    const lessMoreFn = (name, lessMr) => {
        return <>
            {name && name.length > 30 ? (
                <>
                    {lessMore[lessMr] ?
                        (<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(lessMr); }}  > ..less </span> </>)
                        :
                        (<>{name.substring(0, 30)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(lessMr); }}  > ..more </span> </>)
                    }
                </>
            ) : name || '--'
            }
        </>
    }
    const toggleLessMore = (lessMr) => {
        setLessMore({
            ...lessMore,
            [lessMr]: !lessMore[lessMr]
        });
    };
    return (
        <TableRow>
            <TableCell>
                {item.activity_type?.label || '--'}
            </TableCell>
            <TableCell>
                {item.alert_frequency_type?.label || '--'}

                {item.alert_frequency_type?.value == 2 ?
                    <p style={{ fontSize: "13px" }}>On {item.notify_every_week_on?.label || '--'} at 1:30 AM UTC</p>
                    : null
                }
                {item.alert_frequency_type?.value == 3 ?
                    <p style={{ fontSize: "13px" }}> {item.notify_every_month_on?.value}{getOrdinal(item.notify_every_month_on?.value)} of every month at 1:30 AM UTC</p>
                    : null
                }
                {item.alert_frequency_type?.value == 0 ?
                    <p style={{ fontSize: "13px" }}>At 1:30 AM UTC</p>
                    : null
                }


            </TableCell>
            <TableCell>
                {item.valid_till ? moment(item.valid_till).format('MMM DD, YYYY') : '--'}
                <p style={{ fontSize: "11px" }}>
                    {item.valid_till ?
                        moment(item.valid_till).diff(moment().format(displayDateFormat)) < 0 ?
                            <Chip size='small' label='Expired' style={{ background: '#d91717', color: '#fff' }} />
                            :
                            <Chip size='small' label='Active' style={{ background: '#008000', color: '#fff' }} />

                        : null
                    }
                </p>

            </TableCell>
            <TableCell >
                <Tooltip arrow title={item.user_names}>
                    <span>{lessMoreFn(item.user_names, "user_names")}
                        {/* {item.user_names.length < 30 ?
                            item.user_names
                            :
                            <span>
                                {item.user_names.slice(0, 30)} <span style={{ color: '#058ad5', cursor: 'pointer' }}>...more</span>
                            </span>
                        } */}
                    </span>
                </Tooltip>
            </TableCell>

            <TableCell>
                {item.created_by_display?.name || '--'}
                <p style={{ fontSize: "13px" }}>
                    {item.created_at ? moment(item.created_at).format('MMM DD, YYYY hh:mm A') : '--'}
                </p>
            </TableCell>
            <TableCell align='right'>
                <Tooltip arrow title='View details'>
                    <VisibilityIcon style={{ cursor: "pointer" }} color='primary' fontSize='small'
                        onClick={() => setEditAlerts(item, 'view')}
                    />
                </Tooltip>
                <Tooltip arrow title='Edit'>
                    <Edit style={{ cursor: "pointer" }} color='primary' fontSize='small' onClick={() => setEditAlerts(item)} />
                </Tooltip>
                <Tooltip arrow title='Delete' >
                    <Delete style={{ cursor: "pointer" }} color='secondary' fontSize='small' variant='outlined' onClick={() => setDeleteAlert(item?.id)} />
                </Tooltip>
                <Tooltip arrow title='Emails Sent History'>
                    <TimelineIcon style={{ cursor: "pointer" }} color='primary' fontSize='small' onClick={() => setRevionAlert(item)} />
                </Tooltip>
                <RevisionHistory queryParams={{object_id: item.id }} module="customalerts" url={`audit/camo/customalert/`} buttonType={true} />
            </TableCell>
        </TableRow>
    )
}

import { getLocalStorageInfo } from "../../../../utils"

export const ModsTableHeadMain = [
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  {
    id: "ModComplianceDetails",
    label: "MOD Compliance details",
    sortOption: true,
    colspan: 3,
  },

  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
]

export const ModsTableHead = [
  { id: "actions", label: "Actions", sortOption: false },
  { id: "ata_chapter", label: "ATA Chapter", sortOption: true },
  { id: "mod_ref_number", label: "MOD Refrence Number", sortOption: true },
  { id: "mod_issued_by", label: "MOD Issued By", sortOption: true },
  { id: "mod_applicability", label: "MOD Applicability", sortOption: true },
  { id: "description", label: "Description", sortOption: true },
  { id: "type", label: "Type", sortOption: true },
  { id: "effectivity", label: "Effectivity", sortOption: true },

  { id: "effectivity_date", label: "Effectivity Date", sortOption: true },
  { id: "mod_interval_type", label: "MOD Compliance", sortOption: true },
  { id: "mod_interval", label: "MOD Compliance Period", sortOption: true },

  {
    id: "mod_compliance_status",
    label: "MOD Compliance Status",
    sortOption: true,
  },
  //submenu
  { id: "mod_compliance_tsn", label: "TSN", sortOption: false },
  { id: "mod_compliance_csn", label: "CSN", sortOption: false },
  { id: "mod_compliance_Date", label: "Date", sortOption: false },

  { id: "due_at", label: "MOD Next Due", sortOption: false },
  // { id: "document_number", label: "Document Number", sortOption: false },

  // { id: "last_done_date", label: "Last Done Date", sortOption: false },
  // { id: "last_done_fh", label: "Last Done FH", sortOption: false },
  // { id: "last_done_fc", label: "Last Done FC", sortOption: false },

  // { id: "workorder", label: "Workorder", sortOption: false },

  // { id: "remark", label: "Remarks" },
  // { id: "mod_compliance_dfp", label: "MOD Compliance DFP" },
  // { id: "release_certificate", label: "Release Certificate" },
  // { id: "certificate_of_conformity", label: "Certificate Of Conformity" },
  { id: "attachments", label: "Attachments" },
]

export const assetFilterOps = {
  aircraft_type: {
    inputType: "dropdown",
    placeholder: "Select Aircraft Type",
    title: "Aircraft Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  engine_type: {
    inputType: "dropdown",
    placeholder: "Select Engine Type",
    title: "Engine Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  lessee: {
    inputType: "dropdown",
    placeholder: "Select Lessee",
    title: "Lessee",
    options: [],
    labelKey: "name",
    valueKey: "slug",
    multiple: true,
  },
  lessor_name: {
    inputType: "text",
    placeholder: "Enter Lessor",
    title: "Lessor",
  },
  owner: {
    inputType: "text",
    placeholder: "Enter Owner ",
    title: "Owner",
  },
  ownership_type: {
    inputType: "dropdown",
    placeholder: "Select Ownership Type",
    title: "Ownership",
    options: [
      { label: "Owned", value: 1 },
      { label: "Managed", value: 2 },
    ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  registration: {
    inputType: "text",
    placeholder: "Enter Registeration Number",
    title: "Registeration Number",
  },
  portfolio: {
    inputType: "text",
    placeholder: "Enter Portfolio",
    title: "Portfolio",
  },
  serial_number: {
    inputType: "text",
    placeholder: "Enter Serial Number",
    title: "Serial Number",
  },
  status: {
    inputType: "dropdown",
    placeholder: "Select Status",
    title: "Status",
    options:
      getLocalStorageInfo() &&
      getLocalStorageInfo().defaultLessor &&
      getLocalStorageInfo().defaultLessor.id === 442
        ? [
            { label: "Prospect", value: "11" },
            { label: "In Storage", value: "10" },
            { label: "Lease Return", value: "9" },
            { label: "MOU Signed", value: "8" },
            { label: "MOU Issued", value: "7" },
            { label: "Lease Issued", value: "6" },
            { label: "Archive", value: "5" },
            { label: "Written Off/ Sold / Part out", value: "4" },
            { label: "OnGround", value: "3" },
            { label: "Off Lease", value: "2" },
            { label: "On Lease", value: "1" },
          ]
        : [
            { label: "Archive", value: "5" },
            { label: "On Lease", value: "1" },
            { label: "Off Lease", value: "2" },
            { label: "On Ground", value: "3" },
            { label: "Written Off/ Sold / Part out", value: "4" },
          ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
}

import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, Dialog, TextareaAutosize, Tooltip } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from "@material-ui/lab/Autocomplete"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/MasterTaskFormData"
import axios from 'axios';
import { getLocalStorageInfo, format_Commas,validationMess } from "../../../../utils";
import { addMcFormApi, deleteMcAttachment } from "../apiServices"
import { regexConstants } from "../../../../constants/regEx"
import { checkApiStatus } from "../../../../utils"
import { globalPutService } from "../../../../utils/globalApiServices"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { assetType, imgStoragePath,specificKey } from "../../../../constants";
import config from "../../../../config";

class MasterTaskForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: {},
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            btnStatus: "",
            open: false,
            deleteAttachmentId: 0,
            showSuccessUpload: false,
            linkFile: false,
            uploadFile: false
        }
        this.addMcFormApi = addMcFormApi.bind(this)
    }

    componentDidMount = () => {
        if (this.props.editFormId) {
            this.fillEditData()
        }
    }
    handleOpenFile = () => {
        this.setState({ linkFile: true })
    };

    handleCloseFile = () => {
        this.setState({ linkFile: false })
    };
    handleOpenUploadFile = () => {
        this.setState({ uploadFile: true })
    };
    handleUploadFileCloseFile = () => {
        this.setState({ uploadFile: false })
    };

    onFieldChange = (keyParam, value) => {
        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [keyParam]: value,
            },
        }))
    }

    onRestErrorKey = (keyParam) => {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                [keyParam]: "",
            },
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
            validationInputs = {
                ...validationInputs,
                serial_number: taskErrorCode["serial_number"][fieldValidation({ ...taskErrorCode["serial_number_obj"], fieldval: data.serial_number})],
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" }) ) {
            const props = this.props.props
            let payload = Object.assign({}, data)
            payload = {
                ...payload,
                asset_id: props.match.params.asset,
                asset_type_id: props.match.params.assetType,
                tsn: payload.tsn ?  payload.tsn : null,
                csn: payload.csn ?  payload.csn : null,
            }
            addMcFormApi(payload, props,this.props.addForm).then(async (response) => {
                const uploadId = response.data.data.id
                if (uploadId) {
                    await this.uploadFileTest(uploadId, props, false)
                }
                if (uploadId && this.state.btnStatus !== "addAnother") {
                    this.props.closeAddForm()
                } else {
                    return false
                }
            })

        } else {
            this.setState({ error: validationInputs })
        }
    }
    fillEditData = () => {
        const updateId = this.props.editFormId
        this.setState({ attachments: this.props.attachments })
        if (updateId === undefined || updateId === null) {
            this.setState({
                data: {},
            })
        } else {
            this.setState({
                data: this.props.editFormData,
            })
        }
    }
    isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
    }
    updateData = async (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
            validationInputs = {
                ...validationInputs,
                serial_number: taskErrorCode["serial_number"][fieldValidation({ ...taskErrorCode["serial_number_obj"], fieldval: data.serial_number,})],

            }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
            delete data.delete_status
			delete data.delete_timestamp
			delete data.deleted_by
			delete data.restore_timestamp
			delete data.restored_by
			delete data.is_restored
            let payload = Object.assign({}, data)
            const props = this.props.props
            payload = {
                ...payload,
                asset_id: props.match.params.asset,
                asset_type_id: props.match.params.assetType,
                tsn: payload.tsn ?  payload.tsn : null,
                csn: payload.csn ?  payload.csn : null,
            }
            const updateId = this.props.editFormId
            if (updateId) {
                this.uploadFileTest(updateId, props, true)
            }
            this.props.updateFormData(updateId, payload, props,this.props.addForm,this.props.closeAddForm)
            // this.props.closeAddForm()
            this.setState({ error: validationInputs })
        } else {
            this.setState({ error: validationInputs })
        }
    }
    uploadFileTest = async (uploadId, props, isUpdate) => {
        if (this.state.newUploadedAttachments.length > 0) {
            let newAttachmentObj = []
            let formdata = new FormData()
            const module_type_id = 16
            Object.keys(this.state.newUploadedAttachments).map((key, index) => {
                newAttachmentObj.push({
                    file: this.state.newUploadedAttachments[key],
                    file_name: this.state.newUploadedAttachments[key].name,
                    file_type: this.state.newUploadedAttachments[key].type,
                    module_type_id,
                    module_id: uploadId,
                    asset_id: this.props.props.match.params.asset,
                    asset_type_id: this.props.props.match.params.assetType

                })
                formdata.append(
                    "[" + key + "]file",
                    this.state.newUploadedAttachments[key]
                )
                formdata.append(
                    "[" + key + "]file_type",
                    this.state.newUploadedAttachments[key].type
                )
                formdata.append(
                    "[" + key + "]file_name",
                    this.state.newUploadedAttachments[key].name
                )
                formdata.append("[" + key + "]module_id", uploadId)
                formdata.append("[" + key + "]module_type_id", module_type_id)
                formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
                formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
            })
            this.props.fileUploadData(uploadId, formdata, props, true)
            if (this.state.btnStatus === "addAnother") {
                this.resetBothAttachmentArray()
            }
            if (!isUpdate) {
                this.onSubmitFileLink(uploadId)
                this.props.fileUploadData(uploadId, null, props, false)
            }
        } else {
            if (this.state.btnStatus === "addAnother") {
                this.setState((prevState) => ({
                    ...prevState,
                    data: formValues,
                    attachments: [],
                }))
                document.getElementById("asset-form").reset()
                this.props.fileUploadData(uploadId, null, props, false)
            } else {
                if (!isUpdate) {
                    this.onSubmitFileLink(uploadId)
                    this.props.fileUploadData(uploadId, null, props, false)
                }
            }
        }
    }

    resetBothAttachmentArray = () => {
        this.setState((prevState) => ({
            ...prevState,
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            open: false,
            deleteAttachmentId: 0,
        }))
        document.getElementById("asset-form").reset()
    }

    onUpload = (files) => {
        const chosenFiles = Array.prototype.slice.call(files)
        for (let i = 0; i < chosenFiles.length; i++) {
            if (chosenFiles[i].size > 52428800) {
                this.props.props.enqueueSnackbar(
                    `${chosenFiles[i].name} Maximum file size should be 50MB.`,
                    {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }
                )
                delete chosenFiles[i]
                continue
            } else {
                this.handleUploadFiles(chosenFiles)
                // document.querySelector(".MuiDialog-paper").scrollTop = 800;
                this.setState({
                    showSuccessUpload: true,
                })
                setTimeout(() => {
                    if (this.state.attachments) {
                        this.setState({
                            showSuccessUpload: false,
                        })
                    }
                }, 2500)
            }
        }
    }

    handleUploadFiles = (files) => {
        const uploaded = [...this.state.newUploadedAttachments]
        const existingAttachments = [...this.state.attachments]
        files.some((file) => {
            uploaded.push(file)
        })
        files.map((file) => {
            var objc = {
                id: 0,
                module_id: 0,
                module_type_id: 16,
                file_name: file.name,
                file_type: file.type,
                file: "",
                name: file.name,
                asset_id: this.props.props.match.params.asset,
                asset_type_id: this.props.props.match.params.assetType
            }
            existingAttachments.push(objc)
        })

        this.setState({
            newUploadedAttachments: uploaded,
            attachments: existingAttachments,
        })
    }

    handleClickAttachment = (e, id) => {
        this.setState({ open: true })
        this.setState({ deleteAttachmentId: id })
    }

    handleCloseAttachment = (e) => {
        this.setState({ open: false })
        this.setState({ deleteAttachmentId: 0 })
    }

    deleteAttachmentFile = async (id, file) => {
        if (id !== undefined && id != 0) {
            const props = this.props.props
            deleteMcAttachment(id, props)
            var array = [...this.state.attachments]
            array = array.filter((item) => item.id !== id)
            this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
        } else {
            this.onRemoveCamoFile(file)
        }
    }

    removeAttachment = (e, obj) => {
        var array = [...this.state.attachments]
        var newuploadarray = [...this.state.newUploadedAttachments]

        var filteredArray = []

        newuploadarray.map((file) => {
            if (file.name == obj.file_name && file.type == obj.file_type) {
            } else {
                filteredArray.push(file)
            }
        })
        // Set the state with the new filtered array
        array = array.filter((item) => item !== obj)
        this.setState({
            newUploadedAttachments: filteredArray,
            attachments: array,
        })

    }

    saveAndAddAnother = async (e) => {
        await this.setState({ btnStatus: "addAnother" })
        await this.handleSubmit(e)
    }

    addFormData = async (e) => {
        await this.setState({ btnStatus: "add" })
        this.handleSubmit(e)
    }

    getResponseBack = (file) => {
        const { data, } = this.state
        this.setState({
            data: {
                ...data,
                checklist: file,
            },
        });
    }
    listResponseBack = (file) => {
        if (this.props.actionType === "add" || 'edit') {
            return null
        } else {
            this.props.getResponseBack(file)
        }
    }
    downloadFile = (file) => {
        axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
            })
    }
    onUnlinkCheckListFile = (checklist, fileId) => {
        const { data } = this.state;
        let payload = {};
        payload.delete = true;
        payload.folder_ids = checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
        payload.file_ids = checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
        globalPutService(`camo/mc/${this.props.editFormId}/document-checklists/`, payload)
            .then((response) => {
                if (checkApiStatus(response)) {
                    const updatedChecklist = { ...checklist };
                    updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                    updatedChecklist.folders = updatedChecklist?.folders.filter((file) => file.id !== fileId);
                    this.setState({
                        data: {
                            ...data,
                            checklist: updatedChecklist,
                        },
                    });
                }
            });
    }
    onRemoveFile = (file) => {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                checklist: data.checklist.filter(item => item.name !== file.name)
            }
        });
    }
    onRemoveCamoFile = (file) => {
        const { attachments } = this.state;
        this.setState({
            attachments: attachments.filter(item => item.file_name !== file.file_name)
        });
    }

    onLinkFile = (file, uuid, type) => {
        if (type == "link" || uuid) {
            const { data } = this.state;
            const folderUUID = [...data.folder_uuid, uuid];
            const updatedChecklist = [...data.checklist, file];

            let uuidData = [];
            let output = [];

            for (const arr of folderUUID) {
                uuidData = uuidData.concat(arr);
            }

            for (const arr of updatedChecklist) {
                output = output.concat(arr);
            }
            this.setState({
                data: {
                    ...data,
                    checklist: output,
                    folder_uuid: uuidData.join("")
                },
            });
        } else {
            this.onUpload(file)
        }

    }

    onSubmitFileLink = (id) => {
        const { data } = this.state;
        if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
            let payload = {};
            if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            } else if (data?.checklist.some(item => item.type === 'folder')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
            } else {
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            }
            globalPutService(`camo/mc/${id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                        // this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0 && data.folder_uuid) {
            let formData = new FormData();
            formData.append('folder_uuid', data.folder_uuid);
            for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
                formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
            }
            globalPutService(`camo/mc/${id}/document-checklists/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
    }
    render() {
        const { data, error, attachments } = this.state
        return (
            <Fragment>
                <div id="AmpTaskForm">
                    <DialogTitle>
                        {this.props.formTitle}
                        <CloseIcon
                            onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
                            className="close-icon"
                        />
                    </DialogTitle>
                    <form
                        id="asset-form"
                        onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
                    >
                        <DialogContent>
                            <Grid spacing={2} container>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        label="Part Number"
                                        name="part_number"
                                        id="part_number"
                                        inputProps={{ maxLength: 50 }}
                                        InputLabelProps={{ shrink: true }}
                                        value={data.part_number ? data.part_number : ''}
                                        error={error.part_number ? true : false}
                                        helperText={error.part_number ? error.part_number : ""}
                                        onChange={(e) => { this.onFieldChange('part_number', e.target.value); this.onRestErrorKey("part_number") }} // change as per validation sheet
                                        // onChange={(e) => { e.target.value.length <= 25 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('part_number', e.target.value); this.onRestErrorKey("part_number") }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="Serial Number"
                                        id="serial_number"
                                        InputLabelProps={{ shrink: true }}
                                        value={data.serial_number}
                                        inputProps={{ maxLength: 50 }}
                                        error={error.serial_number ? true : false}
                                        helperText={error.serial_number ? error.serial_number : ""}
                                        onChange={(e) => { this.onFieldChange('serial_number', e.target.value); this.onRestErrorKey("serial_number") }} // change as per validation sheet
                                        // onChange={(e) => { e.target.value.length <= 25 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('serial_number', e.target.value); this.onRestErrorKey("serial_number") }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        options={['Yes', 'No']}
                                        getOptionLabel={(option) => option}
                                        id="life_limited_part"
                                        value={data?.life_limited_part || null}
                                        onChange={(e, value) => this.onFieldChange("life_limited_part", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Life Limit Part"
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                placeholder="Select Life Limit Part"
                                                variant="outlined"

                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={12}>
                                    <TextField
                                        multiline
                                        minRows={5}
                                        variant="outlined"
                                        label="Description"
                                        name="description"
                                        id="description"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 250 }}
                                        value={data.description}
                                        onChange={(e) => this.onFieldChange("description", e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                TSN
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        name="tsn"
                                        id="tsn"
                                        error={error.tsn ? true : false}
                                        helperText={error.tsn ? error.tsn : ""}
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.tsn) && 10 }}
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                        value={regexConstants.alphanumespcespclchar.test(data.tsn) ? data.tsn : format_Commas(data?.tsn.replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 7 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("tsn", e.target.value);
                                                this.setState({
                                                    error: {...error,tsn:e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess("TSN",true) }, })

                                            } else if (isValid || value === "") {
                                                this.onFieldChange("tsn", numericValue);
                                                this.setState({
                                                      error: {...error,tsn:regexConstants.numberWithDot.test(numericValue) || e.target.value  === (null || undefined || "") ? "" : e.target.value  === null  ? "" : ""}})
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                CSN
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999</span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        name="csn"
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                        error={error.csn ? true : false}
                                        helperText={error.csn ? error.csn : ""}
                                        inputProps={{maxLength:regexConstants.alphanumespcespclchar.test(data.csn) && 9}}
                                        value={regexConstants.alphanumespcespclchar.test(data.csn) ? data.csn : format_Commas(data?.csn.toString().replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("csn", e.target.value);
												this.setState({error: {...error,csn:e.target?.value  === null  || e.target?.value === ""  ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess("CSN",false)}, })
                                            } else {
											    this.setState({ error: {...error, csn:e.target?.value  === null  || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === ""  ? "" : ""}})
                                                e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && this.onFieldChange('csn', e.target.value); this.onRestErrorKey("csn")
                                            }
                                        }
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Grid spacing={1} container>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Remarks"
                                        margin='normal'
                                        variant='outlined'
                                        fullWidth
                                        value={data.remarks}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => this.onFieldChange("remarks", e.target.value)}
                                        rows={3}
                                        multiline
                                        inputProps={{ maxLength: 250 }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
                                        <ul className="list-inline spacing-list">
                                            <li className="list-inline-item" onClick={this.handleOpenUploadFile} >
                                                <span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
                                            </li>
                                            <li className="list-inline-item" onClick={this.handleOpenFile}>
                                                <span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid id="data-container" spacing={1} container>
                                    <Grid spacing={1} container>
                                        {this.props.actionType === 'add' ?
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.files?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {(data.checklist?.folders?.length > 0) &&
                                                        data.checklist?.folders.map((file) => {
                                                            return (
                                                                <li className='list-inline-item' key={file.id}>
                                                                    <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                                        <a href={`${config.domain.records}${assetType[this.props.props?.match.params.assetType]}/${file?.asset_slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                                    </Tooltip>
                                                                    <span className='file-name'>{file.name}</span>
                                                                    <span className='file-remove'>
                                                                        <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                                    </span>
                                                                </li>
                                                            );
                                                        })}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id, attachments)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className="actions-btn">
                            <Button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
                            >
                                {this.props.actionType === "add" ? "Add" : "Update"}
                            </Button>

                            {this.props.actionType === "add" ? (<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
                            ) : (
                                ""
                            )}
                            <Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
                        </DialogActions>
                        <Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
                            <LinkFiles
                                handleClose={() => this.handleCloseFile()}
                                uuid={this.props?.uuid?.uuid}
                                editFormId={this.props.editFormId}
                                getResponseBack={(file) => this.getResponseBack(file)}
                                listResponseBack={(file) => this.listResponseBack(file)}
                                actionType={this.props.actionType}
                                onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
                                modType="mc"
                                uploadFileInfo={data}
                                last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
                                attachments={attachments}
                                type="link"
                            />
                        </Dialog>
                        <Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
                            <UploadFile
                                handleClose={() => this.handleUploadFileCloseFile()}
                                uuid={this.props?.uuid?.uuid}
                                editFormId={this.props.editFormId}
                                getResponseBack={(file) => this.getResponseBack(file)}
                                listResponseBack={(file) => this.listResponseBack(file)}
                                actionType={this.props.actionType}
                                onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
                                modType="mc"
                                uploadFileInfo={data}
                                last_used_folder_uuid={this.props.last_used_folder_uuid}
                                onUpload={(file) => this.onUpload(file)}
                                type="upload"
                                checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
                                checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
                            />
                        </Dialog>
                    </form>
                </div>
            </Fragment>
        )
    }
}

export default MasterTaskForm

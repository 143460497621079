import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableContainer, TableHead, TableCell, TableBody, Table, TableRow, TextField, TextareaAutosize, Tooltip } from "@material-ui/core"
import React, { Component, Fragment } from "react"
import CloseIcon from "@material-ui/icons/Close"
import Autocomplete from "@material-ui/lab/Autocomplete"
import axios from 'axios';
import InfoIcon from '@material-ui/icons/Info';
import { formValues, taskErrorCode } from "../containers/LLpTaskFormData"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { checkApiStatus,getLocalStorageInfo, format_Commas ,validationMess} from "../../../../utils";
import { addLLpTaskFormApi, deleteAttachment, } from "../apiService";
import { fieldValidation } from "../../../../utils/formValidation";
import LLpConfirmDeleteAttachment from "./LLpConfirmDeleteAttachment";
import { regexConstants } from "../../../../constants/regEx";
import { globalPutService } from "../../../../utils/globalApiServices";
import LinkFiles from "../../../../shared_elements/components/LinkFiles";
import UploadFile from "../../../../shared_elements/components/UploadFile";
import { trackActivity } from '../../../../utils/mixpanel';
import { assetType, imgStoragePath,specificKey } from "../../../../constants";
import config from "../../../../config";
class LLpTaskForms extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: {},
			data: formValues,
			numberError: false,
			attachments: [],
			newUploadedAttachments: [],
			btnStatus: "",
			open: false,
			deleteAttachmentId: 0,
			showSuccessUpload: false,
		}

		this.addLLpTaskFormApi = addLLpTaskFormApi.bind(this)
	}

	handleOpenFile = () => {
		this.setState({ linkFile: true })
	};
	handleCloseFile = () => {
		this.setState({ linkFile: false })
	};
	handleOpenUploadFile = () => {
		this.setState({ uploadFile: true })
	};
	handleUploadFileCloseFile = () => {
		this.setState({ uploadFile: false })
	};

	componentDidMount = () => {
		if (this.props.editFormId) {
			this.fillEditData()
		}
		trackActivity('CAMO', {
			event_type: 'Modal Opened',
			page_title: 'LLP Add Task Form',
		})
	}
	onFieldChange = (keyParam, value, index) => {
		const { error } = this.state;
		this.setState((prevState) => {
			const newData = { ...prevState.data };
			if (keyParam === "cootr_thrust") {
				const thrustKey = index >= 0 ? `COOTR_thrust_${String.fromCharCode(97 + index)}` : keyParam;
				newData[thrustKey] = format_Commas(value).replace(/[^0-9.]/g, '');
			} else if (keyParam === "life_limit") {
				const thrustKey = index >= 0 ? `life_limit_thrust_${String.fromCharCode(97 + index)}` : keyParam;
				newData[thrustKey] = regexConstants.alphanumespcespclchar.test(value) ? value :format_Commas(value).replace(/[^0-9.]/g, '')
				if (regexConstants.alphanumespcespclchar.test(value)) {
					this.setState({ error: {...error, [thrustKey] : value  === (null || undefined || "")  ? "" : !['NO LIMIT'].includes(value.toUpperCase()) && 'Allow keywords No limit or 0 to 99999'}, })
				} else {
					this.setState({ error: {...error, [thrustKey]:value  === null  || regexConstants.numberWithComma.test(value) || value === ""  ? "" : ""}})
				}
			} else {
				newData[keyParam] = value;
			}
			return { data: newData };
		});
		
	};

	onRestErrorKey = (keyParam, index) => {
		this.setState((prevState) => {
			const { error } = prevState;
			if (keyParam === "life_limit") {
				const updatedError = {};
				for (let i = 0; i < 5; i++) {
					const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + i)}`;
					updatedError[thrustKey] = "";
				}
				return { error: { ...error, ...updatedError } };
			}

			return { error: { ...error, [keyParam]: "" } };
		});
	};

	handleSubmit = (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data,error } = this.state
			const { thrust_detail } = this.props
			validationInputs = {
				...validationInputs,
				module_type: taskErrorCode["module_type"][fieldValidation({ ...taskErrorCode["module_type_obj"], fieldval: data.module_type, })],
				description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
				part_number: taskErrorCode["part_number"][fieldValidation({ ...taskErrorCode["part_number_obj"], fieldval: data.part_number, })],
				serial_number: taskErrorCode["serial_number"][fieldValidation({ ...taskErrorCode["serial_number_obj"], fieldval: data.serial_number, })],
				tsn: taskErrorCode["tsn"][fieldValidation({ ...taskErrorCode["tsn_obj"], fieldval: data.tsn, })],
				life_limit_thrust_a: data?.life_limit_thrust_a ? '' : taskErrorCode["life_limit_thrust_a"][fieldValidation({ ...taskErrorCode["life_limit_thrust_a_obj"], fieldval: data.life_limit_thrust_a, })],
				life_limit_thrust_b: data?.life_limit_thrust_b ? '' : taskErrorCode["life_limit_thrust_b"][fieldValidation({ ...taskErrorCode["life_limit_thrust_b_obj"], fieldval: data.life_limit_thrust_b, })],
				life_limit_thrust_c: thrust_detail && Object.values(thrust_detail)?.length === 2 ? '' : data?.life_limit_thrust_c ? '' : taskErrorCode["life_limit_thrust_c"][fieldValidation({ ...taskErrorCode["life_limit_thrust_c_obj"], fieldval: data.life_limit_thrust_c, })],
				life_limit_thrust_d: thrust_detail && Object.values(thrust_detail)?.length === 2 || Object.values(thrust_detail)?.length === 3 ? '' : data?.life_limit_thrust_d ? '' : taskErrorCode["life_limit_thrust_d"][fieldValidation({ ...taskErrorCode["life_limit_thrust_d_obj"], fieldval: data.life_limit_thrust_d, })],
				life_limit_thrust_e: thrust_detail && Object.values(thrust_detail)?.length === 2 || Object.values(thrust_detail)?.length === 3 ? '' : data?.life_limit_thrust_e ? '' : taskErrorCode["life_limit_thrust_e"][fieldValidation({ ...taskErrorCode["life_limit_thrust_e_obj"], fieldval: data.life_limit_thrust_e, })],
			}
		if (data.tsn) {
			const { error } = this.state
			validationInputs = {
				...validationInputs,
				tsn:error?.tsn || '',
			}
		}
		if (data?.life_limit_thrust_a) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_a: error?.life_limit_thrust_a || '',
			}
		}
		if (data?.life_limit_thrust_b) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_b: error?.life_limit_thrust_b || '',
			}
		}
		if (data?.life_limit_thrust_c) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_c: error?.life_limit_thrust_c || '',
			}
		}
		if (data?.life_limit_thrust_d) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_d: error?.life_limit_thrust_d || '',
			}
		}
		if (data?.life_limit_thrust_e) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_e: error?.life_limit_thrust_e || '',
			}
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })
		) {
			const props = this.props.props
			let payload = Object.assign({}, data)
			payload = {
				...payload,
				asset_id: props.match.params.asset,
				asset_type_id: props.match.params.assetType,
				part_number: payload.part_number || null,
				serial_number: payload.serial_number || null,
				tsn: payload.tsn || null,
				csn: parseInt(data.COOTR_thrust_a) + parseInt(data.COOTR_thrust_b) + parseInt(data.COOTR_thrust_c) + parseInt(data.COOTR_thrust_d) + parseInt(data.COOTR_thrust_e) || null,
				COOTR_thrust_a: parseInt(data.COOTR_thrust_a) || null,
				COOTR_thrust_b: parseInt(data.COOTR_thrust_b) || null,
				COOTR_thrust_c: parseInt(data.COOTR_thrust_c) || null,
				COOTR_thrust_d: parseInt(data.COOTR_thrust_d) || null,
				COOTR_thrust_e: parseInt(data.COOTR_thrust_e) || null,
				life_limit_thrust_a: data.life_limit_thrust_a ? regexConstants.onlyNumeric.test(data.life_limit_thrust_a) ? parseInt(data.life_limit_thrust_a) : data.life_limit_thrust_a :null,
				life_limit_thrust_b: data.life_limit_thrust_b ? regexConstants.onlyNumeric.test(data.life_limit_thrust_b) ? parseInt(data.life_limit_thrust_b) : data.life_limit_thrust_b :null,
				life_limit_thrust_c: data.life_limit_thrust_c ? regexConstants.onlyNumeric.test(data.life_limit_thrust_c) ? parseInt(data.life_limit_thrust_c) : data.life_limit_thrust_c :null,
				life_limit_thrust_d: data.life_limit_thrust_d ? regexConstants.onlyNumeric.test(data.life_limit_thrust_d) ? parseInt(data.life_limit_thrust_d) : data.life_limit_thrust_d :null,
				life_limit_thrust_e: data.life_limit_thrust_e ? regexConstants.onlyNumeric.test(data.life_limit_thrust_e) ? parseInt(data.life_limit_thrust_e) : data.life_limit_thrust_e :null,
			}
			addLLpTaskFormApi(payload, props,this.props.addForm).then(async (response) => {
				const uploadId = response.data?.data?.id
				if (uploadId) {
					await this.uploadFileTest(uploadId, props, false)
					trackActivity('CAMO', {
						event_type: 'Task Added Successfully',
						page_title: 'LLP',
						item_id: uploadId ? uploadId : '',
						request_body: payload ? payload : '',
						response_msg: response.data.message ? response.data.message : ''
					})
				}
				this.props.getResponseBack()
				if (uploadId && this.state.btnStatus !== "addAnother") {
					this.props.closeAddForm()
				} else {
					return false
				}
			})
		

		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Add TASK Failed',
				page_title: 'LLP',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}

	fillEditData = () => {
		const updateId = this.props.editFormId
		this.setState({ attachments: this.props.attachments })
		if (updateId === undefined || updateId === null) {
			this.setState({
				data: {},
			})
		} else {
			this.setState({
				data: this.props.editFormData,
			})
		}
	}

	updateData = async (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data,error } = this.state
		if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
			const { thrust_detail } = this.props
			validationInputs = {
				...validationInputs,
				module_type: taskErrorCode["module_type"][fieldValidation({ ...taskErrorCode["module_type_obj"], fieldval: data.module_type, })],
				description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
				part_number: taskErrorCode["part_number"][fieldValidation({ ...taskErrorCode["part_number_obj"], fieldval: data.part_number, })],
				serial_number: taskErrorCode["serial_number"][fieldValidation({ ...taskErrorCode["serial_number_obj"], fieldval: data.serial_number, })],
				tsn: taskErrorCode["tsn"][fieldValidation({ ...taskErrorCode["tsn_obj"], fieldval: data.tsn, })],
				life_limit_thrust_a: data?.life_limit_thrust_a ? '' : taskErrorCode["life_limit_thrust_a"][fieldValidation({ ...taskErrorCode["life_limit_thrust_a_obj"], fieldval: data.life_limit_thrust_a, })],
				life_limit_thrust_b: data?.life_limit_thrust_b ? '' : taskErrorCode["life_limit_thrust_b"][fieldValidation({ ...taskErrorCode["life_limit_thrust_b_obj"], fieldval: data.life_limit_thrust_b, })],
				life_limit_thrust_c: thrust_detail && Object.values(thrust_detail)?.length === 2 ? '' : data?.life_limit_thrust_c ? '' : taskErrorCode["life_limit_thrust_c"][fieldValidation({ ...taskErrorCode["life_limit_thrust_c_obj"], fieldval: data.life_limit_thrust_c, })],
				life_limit_thrust_d: thrust_detail && Object.values(thrust_detail)?.length === 2 || Object.values(thrust_detail)?.length === 3 ? '' : data?.life_limit_thrust_d ? '' : taskErrorCode["life_limit_thrust_d"][fieldValidation({ ...taskErrorCode["life_limit_thrust_d_obj"], fieldval: data.life_limit_thrust_d, })],
				life_limit_thrust_e: thrust_detail && Object.values(thrust_detail)?.length === 2 || Object.values(thrust_detail)?.length === 3 ? '' : data?.life_limit_thrust_e ? '' : taskErrorCode["life_limit_thrust_e"][fieldValidation({ ...taskErrorCode["life_limit_thrust_e_obj"], fieldval: data.life_limit_thrust_e, })],

			}
		}
		if (data.tsn) {
			const { error } = this.state
			validationInputs = {
				...validationInputs,
				tsn: error?.tsn || '',
			}
		}
		if (data?.life_limit_thrust_a) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_a: error?.life_limit_thrust_a || '',
			}
		}
		if (data?.life_limit_thrust_b) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_b: error?.life_limit_thrust_b || '',
			}
		}
		if (data?.life_limit_thrust_c) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_c: error?.life_limit_thrust_c || '',
			}
		}
		if (data?.life_limit_thrust_d) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_d: error?.life_limit_thrust_d || '',
			}
		}
		if (data?.life_limit_thrust_e) {
			validationInputs = {
				...validationInputs,
				life_limit_thrust_e: error?.life_limit_thrust_e || '',
			}
		}

		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
			delete data.delete_status
			delete data.delete_timestamp
			delete data.deleted_by
			delete data.restore_timestamp
			delete data.restored_by
			delete data.is_restored
			let payload = Object.assign({}, data)
			payload = {
				...payload,
				part_number: payload.part_number || null,
				serial_number: payload.serial_number || null,
				tsn: payload.tsn  || null,
				csn: parseInt(data.COOTR_thrust_a) + parseInt(data.COOTR_thrust_b) + parseInt(data.COOTR_thrust_c) + parseInt(data.COOTR_thrust_d) + parseInt(data.COOTR_thrust_e) || null,
				COOTR_thrust_a: parseInt(data.COOTR_thrust_a) || null,
				COOTR_thrust_b: parseInt(data.COOTR_thrust_b) || null,
				COOTR_thrust_c: parseInt(data.COOTR_thrust_c) || null,
				COOTR_thrust_d: parseInt(data.COOTR_thrust_d) || null,
				COOTR_thrust_e: parseInt(data.COOTR_thrust_e) || null,
				life_limit_thrust_a: data.life_limit_thrust_a ? regexConstants.onlyNumeric.test(data.life_limit_thrust_a) ? parseInt(data.life_limit_thrust_a) : data.life_limit_thrust_a :null,
				life_limit_thrust_b: data.life_limit_thrust_b ? regexConstants.onlyNumeric.test(data.life_limit_thrust_b) ? parseInt(data.life_limit_thrust_b) : data.life_limit_thrust_b :null,
				life_limit_thrust_c: data.life_limit_thrust_c ? regexConstants.onlyNumeric.test(data.life_limit_thrust_c) ? parseInt(data.life_limit_thrust_c) : data.life_limit_thrust_c :null,
				life_limit_thrust_d: data.life_limit_thrust_d ? regexConstants.onlyNumeric.test(data.life_limit_thrust_d) ? parseInt(data.life_limit_thrust_d) : data.life_limit_thrust_d :null,
				life_limit_thrust_e: data.life_limit_thrust_e ? regexConstants.onlyNumeric.test(data.life_limit_thrust_e) ? parseInt(data.life_limit_thrust_e) : data.life_limit_thrust_e :null,
			}
			const updateId = this.props.editFormId
			const props = this.props.props
			if (updateId) {
				this.uploadFileTest(updateId, props, true)
			}
			this.props.updateFormData(updateId, payload, props,this.props.addForm,this.props.closeAddForm)
			// this.props.closeAddForm()
			trackActivity('CAMO', {
				event_type: 'Task Updated Successfully',
				page_title: 'LLP',
				item_id: updateId ? updateId : '',
				request_body: payload ? payload : ''
			})
			this.setState({ error: validationInputs })
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Update TASK Failed',
				page_title: 'LLP',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}

	uploadFileTest = async (uploadId, props, isUpdate) => {
		if (this.state.newUploadedAttachments.length > 0) {
			let newAttachmentObj = []
			let formdata = new FormData()
			const module_type_id = 8
			Object.keys(this.state.newUploadedAttachments).map((key, index) => {
				newAttachmentObj.push({
					file: this.state.newUploadedAttachments[key],
					file_name: this.state.newUploadedAttachments[key].name,
					file_type: this.state.newUploadedAttachments[key].type,
					module_type_id,
					module_id: uploadId,
					asset_id: this.props.props.match.params.asset,
					asset_type_id: this.props.props.match.params.assetType

				})
				formdata.append(
					"[" + key + "]file",
					this.state.newUploadedAttachments[key]
				)
				formdata.append(
					"[" + key + "]file_type",
					this.state.newUploadedAttachments[key].type
				)
				formdata.append(
					"[" + key + "]file_name",
					this.state.newUploadedAttachments[key].name
				)
				formdata.append("[" + key + "]module_id", uploadId)
				formdata.append("[" + key + "]module_type_id", module_type_id)
				formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
				formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
			})
			// uploadOccmFile(uploadId, formdata, props)
			this.props.fileUploadData(uploadId, formdata, props, true)
			if (this.state.btnStatus === "addAnother") {
				this.resetBothAttachmentArray()
			}
			if (!isUpdate) {
				this.onSubmitFileLink(uploadId)
				this.props.fileUploadData(uploadId, null, props, false)
			}
		} else {
			if (this.state.btnStatus === "addAnother") {
				this.setState((prevState) => ({
					...prevState,
					data: formValues,
					attachments: [],
				}))
				document.getElementById("asset-form").reset()
				this.props.fileUploadData(uploadId, null, props, false)
			} else {
				if (!isUpdate) {
					this.onSubmitFileLink(uploadId)
					this.props.fileUploadData(uploadId, null, props, false)
				}
			}
		}
	}

	resetBothAttachmentArray = () => {
		this.setState((prevState) => ({
			...prevState,
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			open: false,
			deleteAttachmentId: 0,
		}))
		document.getElementById("asset-form").reset()
	}

	onUpload = (files) => {
		const chosenFiles = Array.prototype.slice.call(files)
		for (let i = 0; i < chosenFiles.length; i++) {
			if (chosenFiles[i].size > 52428800) {
				this.props.props.enqueueSnackbar(
					`${chosenFiles[i].name} Maximum file size should be 50MB.`,
					{
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					}
				)
				delete chosenFiles[i]
				continue
			} else {
				this.handleUploadFiles(chosenFiles)
				// document.querySelector(".MuiDialog-paper").scrollTop = 800;
				this.setState({
					showSuccessUpload: true,
				})
				setTimeout(() => {
					if (this.state.attachments) {
						this.setState({
							showSuccessUpload: false,
						})
					}
				}, 2500)
			}
		}
	}


	handleUploadFiles = (files) => {
		const uploaded = [...this.state.newUploadedAttachments]
		const existingAttachments = [...this.state.attachments]
		files.some((file) => {
			uploaded.push(file)
		})
		files.map((file) => {
			var objc = {
				id: 0,
				module_id: 0,
				module_type_id: 8,
				file_name: file.name,
				file_type: file.type,
				file: "",
				name: file.name,
			}
			existingAttachments.push(objc)
		})
		this.setState({
			newUploadedAttachments: uploaded,
			attachments: existingAttachments,
		})
	}

	handleClickAttachment = (e, id) => {
		this.setState({ open: true })
		this.setState({ deleteAttachmentId: id })
	}

	handleCloseAttachment = (e) => {
		this.setState({ open: false })
		this.setState({ deleteAttachmentId: 0 })
	}

	deleteAttachmentFile = async (id, file) => {
		if (id !== undefined && id != 0) {
			const props = this.props.props
			deleteAttachment(id, props)
			var array = [...this.state.attachments]
			array = array.filter((item) => item.id !== id)
			this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
		} else {
			this.onRemoveCamoFile(file)
		}
	}


	saveAndAddAnother = async (e) => {
		await this.setState({ btnStatus: "addAnother" })
		await this.handleSubmit(e)
	}

	addFormData = async (e) => {
		await this.setState({ btnStatus: "add" })
		this.handleSubmit(e)
	}
	downloadFile = (file) => {
		axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name);
				document.body.appendChild(link);
				link.click();
			})
	}
	onUnlinkCheckListFile = (checklist, fileId) => {
		const { data } = this.state;
		let payload = {};
		payload.delete = true;
		payload.folder_ids = checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
		payload.file_ids = checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
		globalPutService(`camo/llp/${this.props.editFormId}/document-checklists/`, payload)
			.then((response) => {
				if (checkApiStatus(response)) {
					const updatedChecklist = { ...checklist };
					updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
					updatedChecklist.folders = updatedChecklist?.folders.filter((file) => file.id !== fileId);
					this.setState({
						data: {
							...data,
							checklist: updatedChecklist,
						},
					});
				}
				trackActivity('CAMO', {
					event_type: 'Checklist File Unlinked',
					page_title: 'LLP',
					file_id: fileId ? fileId : '',
					edited_form_id: this.props.editFormId ? this.props.editFormId : '',
					request_body: payload ? payload : ''
				})
			});
	}
	onRemoveFile = (file) => {
		const { data } = this.state;
		this.setState({
			data: {
				...data,
				checklist: data.checklist.filter(item => item.name !== file.name)
			}
		});
	}

	getResponseBack = (file) => {
		const { data, } = this.state
		this.setState({
			data: {
				...data,
				checklist: file,
			},
		});
	}
	listResponseBack = (file) => {
		this.props.getResponseBack(file)
	}

	onRemoveCamoFile = (file) => {
		const { attachments } = this.state;
		this.setState({
			attachments: attachments.filter(item => item.file_name !== file.file_name)
		});
	}

	onLinkFile = (file, uuid, type) => {
		if (type == "link" || uuid) {
			const { data } = this.state;
			const folderUUID = [...data.folder_uuid, uuid];
			const updatedChecklist = [...data.checklist, file];

			let uuidData = [];
			let output = [];

			for (const arr of folderUUID) {
				uuidData = uuidData.concat(arr);
			}

			for (const arr of updatedChecklist) {
				output = output.concat(arr);
			}
			this.setState({
				data: {
					...data,
					checklist: output,
					folder_uuid: uuidData.join("")
				},
			});
		} else {
			this.onUpload(file)
		}
	}

	onSubmitFileLink = (id) => {
		const { data } = this.state;
		if (data.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
			let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
			globalPutService(`camo/llp/${id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0 && data.folder_uuid) {
			let formData = new FormData();
			formData.append('folder_uuid', data.folder_uuid);
			for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
				formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
			}
			globalPutService(`camo/llp/${id}/document-checklists/`, formData)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
	}
	lifeLimithelperText = (index) => {
		const { error } = this.state;
		const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + index)}`;
		return error[thrustKey] || "";
	}

	lifeLimitError = (index) => {
		const { error } = this.state;
		const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + index)}`;
		return !!error[thrustKey];
	}

	operatedThrustValue = (index) => {
		const { data } = this.state;
		const thrustKey = `COOTR_thrust_${String.fromCharCode(97 + index)}`;
		return format_Commas(data[thrustKey]) || '';
	}

	lifeLimitValue = (index) => {
		const { data } = this.state;
		const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + index)}`;
		return format_Commas(data[thrustKey]) || '';
	}
	

	remainingCycle_thrustabcde = (index) => {
		const { data } = this.state
		if (index === 0) {
			return  data.remaining_cycle_thrust_a = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e)  ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_a)) < 0  ? "0" : Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_a))  : null;

		} else if (index === 1) {
			return  data.remaining_cycle_thrust_b = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_b)) < 0  ? "0" : Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_b))   : null;

		} else if (index === 2) {
			return  data.remaining_cycle_thrust_c = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_c)) < 0  ? "0" : Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_c))  : null;
		} else if (index === 3) {
			return  data.remaining_cycle_thrust_d = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_d)) < 0  ? "0" : Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_d))  : null;
		} else if (index === 4) {
			return  data.remaining_cycle_thrust_e = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_e)) < 0  ? "0" : Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_e)) : null;
		}

	}
	remainingCycle_thrustabc = (index) => {
		const { data } = this.state
		if (index === 0) {
			return  data.remaining_cycle_thrust_a = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) 
				)) * parseInt(data.life_limit_thrust_a))  < 0  ? "0" : Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) 
				)) * parseInt(data.life_limit_thrust_a)) : '' ;

		} else if (index === 1) {
			return  data.remaining_cycle_thrust_b = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) 
				)) * parseInt(data.life_limit_thrust_b))  < 0  ? "0" : Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) 
				)) * parseInt(data.life_limit_thrust_b))  : '';

		} else if (index === 2) {
			return  data.remaining_cycle_thrust_c = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c))
				)) * parseInt(data.life_limit_thrust_c))  < 0  ? "0" : Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c))
				)) * parseInt(data.life_limit_thrust_c))  : '';
		} 

	}
	remainingCycle_thrustab = (index) => {
		const { data } = this.state
		if (index === 0) {
			return  data.remaining_cycle_thrust_a = (data.COOTR_thrust_a && data.COOTR_thrust_b) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b))
				  )) * parseInt(data.life_limit_thrust_a))  < 0  ? "0" : Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b))
				  )) * parseInt(data.life_limit_thrust_a))  : '' ;

		} else if (index === 1) {
			return  data.remaining_cycle_thrust_b = (data.COOTR_thrust_a && data.COOTR_thrust_b) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b))
				)) * parseInt(data.life_limit_thrust_b)) < 0  ? "0" :  Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b))
				)) * parseInt(data.life_limit_thrust_b))   : '';
		} 
	}
	csnSum = () => {
		const { data } = this.state
		const { thrust_detail } = this.props
		if (thrust_detail && Object.keys(thrust_detail)?.length === 5) {
			return parseInt(data.COOTR_thrust_a) + parseInt(data.COOTR_thrust_b) + parseInt(data.COOTR_thrust_c) + parseInt(data.COOTR_thrust_d) + parseInt(data.COOTR_thrust_e) || data.csn || ''
		} else if (thrust_detail && Object.keys(thrust_detail)?.length === 3) {
			return parseInt(data.COOTR_thrust_a) + parseInt(data.COOTR_thrust_b) + parseInt(data.COOTR_thrust_c) || data.csn || ''
		} else if (thrust_detail && Object.keys(thrust_detail)?.length === 2) {
			return parseInt(data.COOTR_thrust_a) + parseInt(data.COOTR_thrust_b) || data.csn || ''
		} else {
			return data.csn
		}
	}


	render() {
		const { data, error, attachments,limit_key } = this.state
		const { thrust_detail } = this.props
		const thrust = ["Thrust A", "Thrust B", "Thrust C", "Thrust D", "Thrust E"]
		const modulType = ["FAN", "HPC", "HPT", "LPC", "LPT", "STATIC", "BLADES"]
		return (
			<Fragment>
				<div id="AmpTaskForm">
					<div id="adTaskForm">
						<DialogTitle>
							{this.props.formTitle}
							<CloseIcon
								onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
								className="close-icon"
							/>
						</DialogTitle>
						<form
							id="asset-form"
							onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
						>
							<DialogContent>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<TextField
											required
											variant="outlined"
											label="Part Number"
											name="part_number"
											id="part_number"
											inputProps={{ maxLength: 50 }}
											InputLabelProps={{ shrink: true }}
											value={data.part_number ? data.part_number : ''}
											error={error.part_number ? true : false}
											helperText={error.part_number ? error.part_number : ""}
											onChange={(e) =>{this.onFieldChange('part_number', e.target.value); this.onRestErrorKey("part_number")}} // change as per validation sheet
											// onChange={(e) => { e.target.value.length <= 50 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('part_number', e.target.value); this.onRestErrorKey("part_number") }}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											required
											variant="outlined"
											label="Serial Number"
											name="ad_no"
											id="serial_number"
											inputProps={{ maxLength: 50 }}
											InputLabelProps={{ shrink: true }}
											value={data.serial_number}
											error={error.serial_number ? true : false}
											helperText={error.serial_number ? error.serial_number : ""}
											onChange={(e) =>{this.onFieldChange('serial_number', e.target.value); this.onRestErrorKey("serial_number")}} // change as per validation sheet
											// onChange={(e) => { e.target.value.length <= 50 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('serial_number', e.target.value); this.onRestErrorKey("serial_number") }}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											required
											variant="outlined"
											label={
												<span style={{ verticalAlign: 'super' }}>
													TSN
													<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
														<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
													</Tooltip>
												</span>
											}
											name="tsn"
											id="tsn"
											error={error.tsn ? true : false}
											helperText={error.tsn ? error.tsn : ""}
											inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.tsn) && 10 }}
											InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
											value={regexConstants.alphanumespcespclchar.test(data.tsn) ? data.tsn : format_Commas(data?.tsn.replace(/[^0-9.]/g, ''))}
											onChange={(e) => {
												const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
												const numericValue = value.replace(/[^\d.]/g, '');
												const dotCount = (numericValue.match(/\./g) || []).length;
												const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 7 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
												if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
													this.onFieldChange("tsn", e.target.value);
													this.setState({ error: {...error, tsn:e.target.value  === (null || undefined || "")  ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess("TSN",true)}, })
												} else if (isValid || value === "") {
													this.onFieldChange("tsn", numericValue);
													this.onRestErrorKey("tsn");
												}
												
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="CSN"
											disabled
											InputLabelProps={{ shrink: true }}
											value={this.csnSum()}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<Autocomplete
											options={modulType || []}
											getOptionLabel={(option) => option}
											id="module_type"
											value={data?.module_type ? data?.module_type : null}
											onChange={(e, value) => { this.onFieldChange("module_type", value); this.onRestErrorKey("module_type") }}
											renderInput={(params) => (
												<TextField
													{...params}
													required
													label="Module"
													margin="normal"
													fullWidth
													error={error.module_type ? true : false}
													helperText={error.module_type ? error.module_type : ""}
													InputLabelProps={{ shrink: true }}
													placeholder="Select Module"
													variant="outlined"

												/>
											)}
										/>
									</Grid>
									<Grid item xs={9}>
										<TextField
											required
											multiline
											minRows={5}
											variant="outlined"
											label="Description"
											error={error.description ? true : false}
											helperText={error.description ? error.description : ""}
											InputLabelProps={{ shrink: true }}
											inputProps={{maxLength:50}}
											value={data.description}
											onChange={(e) => { this.onFieldChange("description", e.target.value);this.onRestErrorKey("description") }}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<TableContainer>
										<Table aria-label="simple table">
											<TableHead style={{ background: '#f0f2f3' }}>
												<TableRow>
													<TableCell>Thrust</TableCell>
													<TableCell align="left">Cycles Operated</TableCell>
													<TableCell align="left">Life Limit <sup style={{ color: " #ff0000", fontSize: "14px", position: "relative", top: 0 }}> * </sup></TableCell>
													<TableCell align="left">Remaining Cycles</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{(thrust_detail && Object.keys(thrust_detail)?.length === 5 ? thrust_detail && Object.values(thrust_detail) : thrust_detail && Object.keys(thrust_detail)?.length === 2 ? thrust_detail && Object.values(thrust_detail) : thrust_detail && Object.keys(thrust_detail)?.length === 3 ? thrust_detail && Object.values(thrust_detail) : thrust).map((row, index) => (
													<TableRow >
														<TableCell> {row} </TableCell>
														<TableCell>
															<TextField
																variant="outlined"
																name="cootr_thrust"
																keyParams="cootr_thrust"
																id="cootr_thrust"
																inputProps={{maxLength:regexConstants.numberWithComma.test(this.operatedThrustValue(index)) ? 6 : 6 }}
																// onChange={(e) => { e.target.value.length <= 10 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('cootr_thrust', e.target.value, index); this.onRestErrorKey("cootr_thrust") }}
																onChange={(e) => this.onFieldChange('cootr_thrust', e.target.value, index)}
																value={this.operatedThrustValue(index)}
															/>
														</TableCell>
														<TableCell>
															<TextField
																variant="outlined"
																onChange={(e) => { this.onFieldChange('life_limit', e.target.value, index); this.onRestErrorKey("life_limit") }}
																error={this.lifeLimitError(index)}
																helperText={this.lifeLimithelperText(index)}
																inputProps={{maxLength:regexConstants.alphanumespcespclchar.test(this.lifeLimitValue(index)) ? 8 : 6 }}
																value={this.lifeLimitValue(index)}
															/>
														</TableCell>
														<TableCell> <TextField variant="outlined"
															disabled
															value={thrust_detail && Object.keys(thrust_detail)?.length === 5 ? this.remainingCycle_thrustabcde(index) || '' : thrust_detail && Object.keys(thrust_detail)?.length === 3 ? this.remainingCycle_thrustabc(index) || '' : thrust_detail && Object.keys(thrust_detail)?.length === 2 && this.remainingCycle_thrustab(index) || ''}
														/>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={6}>
										<TextField
											label="Remarks"
											margin='normal'
											variant='outlined'
											fullWidth
											value={data.remarks}
											InputLabelProps={{ shrink: true }}
											onChange={(e) => this.onFieldChange("remarks", e.target.value)}
											rows={3}
											multiline
											inputProps={{ maxLength: 250 }}
										/>
									</Grid>
									<Grid item xs={6}>
										<div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
											<ul className="list-inline spacing-list">
												<li className="list-inline-item" onClick={this.handleOpenUploadFile} >
													<span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
												</li>
												<li className="list-inline-item" onClick={this.handleOpenFile}>
													<span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
												</li>
											</ul>
										</div>
									</Grid>
									<Grid id="data-container" spacing={1} container>
										<Grid spacing={1} container>
											{this.props.actionType === 'add' ?
												<div className='checklist-files'>
													<ul className='list-inline'>
														{data?.checklist?.map((file) =>
															<li className='list-inline-item'>
																<span className='file-name'>{file.name}</span>
																<span className='file-remove'>
																	<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																	<CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
																</span>
															</li>
														)}
														{attachments?.map((attachments) =>
															<li className='list-inline-item'>
																<span className='file-name'>{attachments.file_name}</span>
																<span className='file-remove'>
																	<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																	<CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
																</span>
															</li>
														)}
													</ul>
												</div>
												:
												<div className='checklist-files'>
													<ul className='list-inline'>
														{data?.checklist?.files?.map((file) =>
															<li className='list-inline-item'>
																<span className='file-name'>{file.name}</span>
																<span className='file-remove'>
																	<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																	<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
																</span>
															</li>
														)}
														{(data.checklist?.folders?.length > 0) &&
															data.checklist?.folders.map((file) => {
																return (
																	<li className='list-inline-item' key={file.id}>
																		<Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
																			<a href={`${config.domain.records}${assetType[this.props.props?.match.params.assetType]}/${file?.asset_slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
																		</Tooltip>
																		<span className='file-name'>{file.name}</span>
																		<span className='file-remove'>
																			<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
																		</span>
																	</li>
																);
															})}
														{attachments?.map((attachments) =>
															<li className='list-inline-item'>
																<span className='file-name'>{attachments.file_name}</span>
																<span className='file-remove'>
																	<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																	<CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id, attachments)} color='secondary' fontSize='small' />
																</span>
															</li>
														)}
													</ul>
												</div>}
										</Grid>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions className="actions-btn">
								<Button
									type="button"
									className="btn btn-primary"
									onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
								>
									{this.props.actionType === "add" ? "Add" : "Update"}
								</Button>

								{this.props.actionType === "add" ? (<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
								) : (
									""
								)}
								<Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
							</DialogActions>
							<Dialog
								open={this.state.open}
								onClose={this.handleCloseAttachment}
								id="htDialog"
							>
								<LLpConfirmDeleteAttachment
									handleCloseAttachment={() => this.handleCloseAttachment()}
									deleteAttachmentFile={() =>
										this.deleteAttachmentFile(this.state.deleteAttachmentId)
									}
								/>
							</Dialog>
							<Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
								<LinkFiles
									handleClose={() => this.handleCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
									modType="llp"
									uploadFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
									attachments={attachments}
									type="link"
								/>
							</Dialog>
							<Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
								<UploadFile
									handleClose={() => this.handleUploadFileCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
									modType="llp"
									uploadFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid}
									onUpload={(file) => this.onUpload(file)}
									type="upload"
									checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
									checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
								/>
							</Dialog>
						</form>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default LLpTaskForms

// /^[\w\-\s]+$/
export const fieldDateFormat = 'DD-MM-YYYY';
export const dayMonthDateFormat = 'DD/MMM/YYYY';
export const backendDateFormat = 'YYYY-MM-DD';
export const displayDateFormat = 'MMMM DD, YYYY';
export const displayDateFormatShort = 'MMM DD, YYYY';
export const fieldDateFormatShort = 'DD-MMM-YY';
export const imgStoragePath = 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/';
export const prettyNumberParamsDB = {
  cssClass:["pretty-number-override-css"],
  justification:'L',
  precision: 2,
  currency: true,
  shortFormat:false, 
  shortFormatMinValue: 99999,
  commafy: true
};
export const specificKey =['NA', 'N/A', 'UNKNOWN', 'UNK']

export const dropzonePreviewUI = {
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  justify: 'flex-start',
  item: {
      style: { padding: 10 },
      xs: 'auto',
  },
  container: {
    spacing: 0,
    margin: 0,
    width: '100%',
    direction: 'row',
    justify: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
}
export const assetType = {
  1: 'aircraft',
  2: 'engine',
  3: 'apu',
  4: 'lg',
  5: 'propeller'
}
export const assetTypeId = {
  'aircraft': 1,
  'engine': 2,
  'apu': 3,
  'lg': 4,
  'propeller': 5
}
export const securityGroups = {
  lessor_admin: 29,
  technical_admin: 24
};
export const assetTypeValues = {
  1: {label:'MSN', urlType:'aircraft'},
  2: {label:'ESN', urlType:'engine'}
};
export const revAssetTypeValues = {
  aircraft:{label:'MSN', assetType:1},
  engine:{label:'ESN', assetType:2},
  apu:{label:'APU', assetType:3},
  lg:{label:'LG', assetType:4},
  propeller:{label:'Propeller', assetType:5},
  fan:{label:'FAN', assetType:6},
  hpc:{label:'HPC', assetType:7},
  hpt:{label:'HPT', assetType:8},
  lpt:{label:'LPT', assetType:9}
};


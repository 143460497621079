import React from 'react'
import { NavLink} from "react-router-dom";

function SideNav(props) {
    return(
        <li>
          <NavLink to={props?.to}>
            {props.title}
          </NavLink>
        </li>
      )
}

export default SideNav